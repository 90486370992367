import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  getters
})

/**
 * 持久化存储
 * 监听每个模块的mutation，如果mutation的type中包含了 '_PERSIST' 后缀标识，则将该模块的state存储到localStorage中
 */
store.subscribe((mutation, state) => {
  const StorageKey = mutation.type.split('/');
  const PayloadKey = StorageKey.pop().split('_');
  if (PayloadKey[PayloadKey.length - 1] === 'PERSIST') {
    // 删除最开始的操作标识
    PayloadKey.shift();
    // 删除最后的PERSIST标识
    PayloadKey.pop();
    let StateKey = '';
    // 将mutation的type转换为state的key
    PayloadKey.forEach((item, index) => {
      if (index === 0) {
        StateKey += item.toLowerCase();
      } else {
        StateKey += item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
      }
    });
    // 获取state的值
    let tempValue = state;
    for (const item of StorageKey) {
      tempValue = tempValue[item];
    }
    localStorage.setItem(`${StorageKey.join('.')}.${StateKey}`, JSON.stringify(tempValue[StateKey]));
  }
});

export default store
