<template>
  <div class="app-container">
    <el-card class="box-card">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" size="mini">
        <el-row type="flex" justify="start" :gutter="20" align="middle">
          <el-col :span="10"><el-form-item label="姓名">
            <el-input v-model="searchForm.user_name" placeholder="姓名" style="width:200px" clearable />
          </el-form-item></el-col>
          <el-col :span="10"><el-form-item label="简历来源">
            <el-select v-model="searchForm.source" clearable placeholder="简历来源" style="width:200px">
              <el-option
                v-for="item in sourcelist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item></el-col>
          <el-col :span="4"><el-button type="primary" size="small" @click="search">查询</el-button></el-col>
        </el-row>
        <el-row />
      </el-form>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-row :gutter="20" type="flex" align="middle" justify="space-between">
          <el-col :span="8">
            <span>待处理简历列表</span>
          </el-col>
          <el-col :span="16" style="text-align: right">
            <el-button size="small" type="primary" @click="importDialogVisible=true">
              导入简历
            </el-button>
          </el-col>
        </el-row>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="table_loading"
        :data="tableList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          color: '#909399',
          background: '#F5F7FA',
        }"
      >
        <el-table-column
          label="姓名"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.user_name }}</template>
        </el-table-column>
        <!-- <el-table-column
          prop="college_name"
          label="学院"
          show-overflow-tooltip
        /> -->
        <el-table-column
          prop="phone_number"
          label="手机号"
          show-overflow-tooltip
        />
        <el-table-column
          prop="email"
          label="邮箱"
          show-overflow-tooltip
        />
        <el-table-column
          prop="delivery_time"
          label="创建时间"
          show-overflow-tooltip
        />
        <el-table-column
          prop="source"
          label="简历来源"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              plain
              disabled
            >{{ sourceName(scope.row.source) }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="assigned_college_time"
          label="分发时间"
          show-overflow-tooltip
        />
        <el-table-column
          fixed="right"
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleDetail(scope.row)">查看</el-button>
            <el-popconfirm v-if="roles[0].id===2 && scope.row.status<=2" style="margin:0 10px" title="请再次确认是否简历通过？" @onConfirm="handleInitalPass(scope.row)"><el-button slot="reference" type="text" size="mini">简历通过</el-button></el-popconfirm>
            <!-- <el-button v-if="roles[0].id===2 && scope.row.status<=2" type="text" size="mini" @click="handleInitalPass(scope.row)">简历通过</el-button> -->
            <el-button v-if="roles[0].id===2 && scope.row.status<=2" type="text" size="mini" @click="handleInitalNoPass(scope.row)">简历不通过</el-button>
            <el-popconfirm v-if="roles[0].id===2 && scope.row.status===5" style="margin:0 10px" title="请再次确认是否学院通过？" @onConfirm="handlePass(scope.row)"><el-button slot="reference" type="text" size="mini">学院通过</el-button></el-popconfirm>
            <!-- <el-button v-if="roles[0].id===2 && scope.row.status===5" type="text" size="mini" @click="handlePass(scope.row)">学院通过</el-button> -->
            <el-button v-if="roles[0].id===2 && scope.row.status===5" type="text" size="mini" @click="handleNoPass(scope.row)">学院不通过</el-button>
            <el-button v-if="roles[0].id===2 && scope.row.import_admin_id === adminid" type="text" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="spaceline" />
      <el-row :gutter="20">
        <el-col :span="16" :offset="8" style="text-align: right">
          <el-pagination
            background
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
    </el-card>
    <ImportDialog
      :visible="importDialogVisible"
      @onCancel="onCancel"
      @onConfirm="onConfirm"
      @onRefresh="onRefresh"
    />
    <DetailDialog
      ref="detailDialog"
      :visible="detailDialogVisible"
      @onCancel="onCancel"
      @onHistory="onHistory"
    />
    <EditDialog
      ref="editDialog"
      :visible="editDialogVisible"
      @onCancel="onCancel"
      @onConfirm="onConfirm"
      @onRefresh="onRefresh"
    />
    <NopassDialog
      ref="nopassDialog"
      :visible="nopassDialogVisible"
      :no-pass-type="2"
      @onCancel="onCancel"
      @getCollegeNoPassReason="getCollegeNoPassReason"
    />
  </div>
</template>

<script>
import { collegeResumeList, initalReview, collegeReview } from '@/api/recruitment-manage'
import { mapGetters } from 'vuex'
import ImportDialog from '../components/ImportDialog' // 导入简历弹框组件
import DetailDialog from '../components/DetailDialog' // 导入简历详情/历史轨迹组件
import EditDialog from '../components/EditDialog' // 编辑简历分发组件
import NopassDialog from '../components/NopassDialog' // 不通过弹框
export default {
  components: {
    ImportDialog,
    DetailDialog,
    EditDialog,
    NopassDialog
  },
  data() {
    return {
      searchForm: {

      },
      tableList: [],
      importDialogVisible: false,
      detailDialogVisible: false,
      editDialogVisible: false,
      nopassDialogVisible: false,

      table_loading: true,
      // 分页
      total: 0,
      currentPage: 1,
      pagesize: 10
    }
  },
  computed: {
    resumeState() {
      return (value) => {
        const result = this.statuslist.find(item => item.status === value);
        return result ? result.name : '-'
      }
    },
    sourceName() {
      return (value) => {
        const result = this.sourcelist.find(item => item.id === value);
        return result ? result.name : '-';
      }
    },
    ...mapGetters(['adminid', 'roles', 'sourcelist', 'statuslist']) // 简历来源列表，简历状态列表
  },
  created() {
    this.initPage()
  },
  methods: {
    search() {
      this.currentPage = 1;
      this.getTableList()
    },
    goto(target) {
      this.$router.push(target)
    },
    // 请求列表数据
    getTableList() {
      this.table_loading = true
      const param = {
        page: this.currentPage,
        size: this.pagesize,
        ...this.searchForm,
      }
      console.log(param)
      if (this.roles[0].id === 2) { // 学院管理员
        collegeResumeList(param)
          .then(res => {
            this.tableList = res.httpCode === 200 ? res.data.list : []
            this.table_loading = false
            this.total = res.httpCode === 200 ? res.data.meta.total : []
          })
          .catch(() => {
            this.$message.error('请求列表发生错误')
            this.table_loading = false
          })
      }
    },
    handleSizeChange(val) {
      this.pagesize = val
      this.getTableList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableList()
    },
    // 查看简历
    handleDetail(row) {
      this.$refs.detailDialog.getResumeDetail(row.id)
      this.detailDialogVisible = true
    },
    // 编辑简历
    handleEdit(row) {
      this.$refs.editDialog.getResumeDetail(row.id)
      this.editDialogVisible = true
    },
    // 简历初审通过
    handleInitalPass(row) {
      initalReview({ id: row.id, result: 1 })
        .then(res => {
          if (res.httpCode === 200 && res.statusCode === 0) {
            this.$message({
              message: '已通过初审',
              type: 'success'
            });
            this.getTableList()
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$message.error('发生错误')
        })
    },
    handleInitalNoPass(row) {
      this.nopassDialogVisible = true
      this.$refs.nopassDialog.getResumeId(row.id)
    },
    // 学院管理员审核通过
    handlePass(row) {
      collegeReview({ id: row.id, result: 1 })
        .then(res => {
          if (res.httpCode === 200 && res.statusCode === 0) {
            this.$message({
              message: '已通过审核',
              type: 'success'
            });
            this.getTableList()
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$message.error('发生错误')
        })
    },
    handleNoPass(row) {
      this.nopassDialogVisible = true
      this.$refs.nopassDialog.getResumeId(row.id)
    },
    // 获取子组件的不通过原因和简历id后并调用不通过接口
    getCollegeNoPassReason(data) {
      const params = {
        ...data,
        result: 0
      }
      collegeReview(params)
        .then((response) => {
          if (response.httpCode === 200 && response.statusCode === 0) {
            this.$message.success(`操作成功`);
            this.getTableList()
          } else {
            this.$message.error(response.message);
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    },
    // 初始化需要调用的接口
    initPage() {
      this.getTableList();
    },

    onCancel() {
      this.importDialogVisible = false
      this.detailDialogVisible = false
      this.editDialogVisible = false
      this.nopassDialogVisible = false
    },
    onConfirm() {
      this.importDialogVisible = false
      this.detailDialogVisible = false
      this.editDialogVisible = false
      this.nopassDialogVisible = false
    },
    onRefresh() {
      this.getTableList()
    },
    onHistory() {
      this.detailDialogVisible = false
      this.nopassDialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  .box-card{
    margin-top: 10px;
  }
  .el-form{
    .el-form-item--mini.el-form-item{
      margin-bottom:0
    }
  }
}
</style>
