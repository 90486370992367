<!--审核不通过弹框组件-->
<template>
  <el-dialog title="填写原因" :visible.sync="visible" :before-close="onCancel" :close-on-click-modal="false">
    <el-form ref="form" :model="form" size="small">
      <el-form-item :required="true">
        <el-input v-model="form.refuse_reason" placeholder="请输入原因" :autosize="{minRows:4,maxRows:8}" type="textarea" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="onCancel">取 消</el-button>
      <el-button size="mini" type="primary" @click="onConfirm('form')">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NopassDialog', // 审核不通过原因组件
  props: {
    visible: {
      // 是否显示
      type: Boolean,
      default: function() {
        return true
      },
    },
    noPassType: {
      // 简历审核不通过类型：1：超级管理员不录用，2：学院管理员不通过或简历初审未通过
      type: Number,
      default: function() {
        return 0
      },
    },

  },
  data() {
    return {
      form: {},
      id: ""// 从父组件获取的当前行数据id
    }
  },
  computed: {
    ...mapGetters(['roles', 'collegelist'])
  },
  mounted() {
  },
  created() {

  },
  methods: {
    onCancel(row) {
      this.$emit('onCancel')
    },
    // 从父级获取当前行数据id
    getResumeId(id) {
      this.id = id;
    },
    onConfirm(formName) {
      if (!this.form.refuse_reason) {
        this.$message.error('请填写不通过原因')
        return;
      }
      this.$emit('onCancel')
      switch (this.noPassType) {
        case 1: // 超级管理员不录用
          this.$emit('getAdminNoPassReason', { refuse_reason: this.form.refuse_reason || '', id: this.id })
          break;
        case 2: // 学院管理员不通过或简历初审不通过
          this.$emit('getCollegeNoPassReason', { refuse_reason: this.form.refuse_reason || '', id: this.id })
          break;
        default:
          break;
      }
    },

  }
}
</script>
