<template>
  <div class="app-container">
    <el-card v-loading="loading" class="box-card">
      <div slot="header" class="clearfix">
        <span>角色列表</span>
      </div>
      <el-table
        :data="rolesList"
        style="width: 100%"
        fit
        highlight-current-row
        :header-cell-style="{
          color: '#909399',
          background: '#F5F7FA',
        }"
      >
        <el-table-column align="center" label="角色 ID" width="120">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="角色名称" width="220">
          <template slot-scope="scope">
            {{ scope.row.role_name }}
          </template>
        </el-table-column>
        <el-table-column align="header-center" label="角色描述">
          <template slot-scope="scope">
            {{ scope.row.description }}
          </template>
        </el-table-column>
        <!-- <el-table-column align="header-center" label="创建时间" width="220">
          <template slot-scope="scope">
            {{ scope.row.created_at }}
          </template>
        </el-table-column> -->
      </el-table>
      <div class="spaceline" />

    </el-card>
  </div>
</template>

<script>
import {
  getRoles
} from "@/api/system_setting";

export default {
  data() {
    return {
      loading: false,
      dailogLoading: false,
      rolesList: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
  },
  created() {
    this.getRoles();
  },
  methods: {
    async getRoles() {
      this.loading = true;
      const param = {
        page: this.currentPage,
        size: this.pageSize,
      };
      const res = await getRoles(param);
      const { list, meta } = res.data
      this.rolesList = list;
      this.total = meta.total;
      this.currentPage = meta.page;
      this.pageSize = meta.page_size;
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.app-container {
  .roles-table {
    margin-top: 30px;
  }
  .permission-tree {
    margin-bottom: 30px;
  }
  .el-input {
      width: 450px;
  }
}
</style>
