import { login, logout, getPermission, getCollegeList, getPositionList } from '@/api/system_setting'
import { getStatusList } from '@/api/recruitment-manage'
import { getToken, setToken, removeToken } from '@/utils/auth'
import avatar from '@/assets/images/avatar.gif'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  adminid: '', // 管理员id
  avatar,
  introduction: '',
  roles: [],
  permissions: [],
  collegeId: '', // 用户所属学院
  collegelist: [], // 学院列表
  positionlist: [], // 职位列表
  statuslist: [], // 简历状态列表
  sourcelist: [], // 简历来源列表
}

const mutations = {
  SET_TOKEN_PERSIST: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME_PERSIST: (state, name) => {
    state.name = name
  },
  SET_ADMINID_PERSIST: (state, adminid) => {
    state.adminid = adminid
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES_PERSIST: (state, roles) => {
    state.roles = roles
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_COLLEGE_ID_PERSIST: (state, college_id) => {
    state.collegeId = college_id
  },
  SET_COLLEGELIST_PERSIST: (state, collegelist) => {
    state.collegelist = collegelist
  },
  SET_POSITIONLIST_PERSIST: (state, positionlist) => {
    state.positionlist = positionlist
  },
  SET_STATUSLIST_PERSIST: (state, statuslist) => {
    state.statuslist = statuslist
  },
  SET_SOURCELIST_PERSIST: (state, sourcelist) => {
    state.sourcelist = sourcelist
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, captcha, captcha_key } = userInfo
    return new Promise((resolve, reject) => {
      login({ account: username.trim(), password: password, captcha, key: captcha_key }).then(response => {
        if (!response.data) { // 登录失败返回reject
          reject(response)
          return;
        }
        const { Authorization: token, name, roles, college_id, id } = response.data;
        commit('SET_TOKEN_PERSIST', token)
        commit('SET_NAME_PERSIST', name)
        commit('SET_ROLES_PERSIST', roles)
        commit('SET_COLLEGE_ID_PERSIST', college_id)
        commit('SET_ADMINID_PERSIST', id)
        setToken(token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 获取学院列表
  getCollegeList({ commit }) {
    getCollegeList()
      .then((response) => {
        commit('SET_COLLEGELIST_PERSIST', response.data)
      })
      .catch(() => {});
  },
  // 获取职位列表
  getPositionList({ commit }) {
    getPositionList()
      .then((response) => {
        commit('SET_POSITIONLIST_PERSIST', response.data)
      })
      .catch(() => {});
  },
  // 获取简历状态
  getStatusList({ commit }) {
    getStatusList()
      .then((response) => {
        commit('SET_STATUSLIST_PERSIST', response.data)
      })
      .catch(() => {});
  },
  // 获取简历来源列表
  getSourcelist({ commit }) {
    commit('SET_SOURCELIST_PERSIST', [{ // 简历来源列表
      id: 0,
      name: '在线投递'
    }, {
      id: 1,
      name: '后台导入(学校)'
    }, {
      id: 2,
      name: '后台导入(学院)'
    }])
  },
  // get role permission
  getPermission({ commit }) {
    return new Promise((resolve, reject) => {
      getPermission().then(response => {
        const { data } = response

        if (!data || data.length <= 0) {
          reject('Verification failed, please Login again.')
        }

        commit('SET_PERMISSIONS', data)
        resolve({ permissions: data })
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN_PERSIST', '')
        commit('SET_NAME_PERSIST', '');
        commit('SET_ROLES_PERSIST', [])
        commit('SET_PERMISSIONS', [])
        removeToken()
        localStorage.clear();
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com//vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN_PERSIST', '')
      commit('SET_ROLES_PERSIST', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = `${role}-token`;

    commit('SET_TOKEN_PERSIST', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
