var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      [
        _c(
          "div",
          { staticClass: "boxs" },
          _vm._l(_vm.boxTitles, function (item) {
            return _c(
              "div",
              {
                key: item.title,
                staticClass: "box",
                style: { cursor: item.cursor },
                on: {
                  click: function ($event) {
                    return _vm.goto(item.target)
                  },
                },
              },
              [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("img", { attrs: { src: item.icon } }),
                ]),
                _c(
                  "div",
                  { staticClass: "bottom", style: { color: item.color } },
                  [
                    _vm._v(" " + _vm._s(item.number)),
                    item.icon === _vm.count_3
                      ? _c("span", [
                          _vm._v(
                            "(学校导入" +
                              _vm._s(item.admin_num) +
                              " + 学院导入" +
                              _vm._s(item.college_num) +
                              ")"
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }