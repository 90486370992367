var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "简历详情",
            visible: _vm.visible,
            "before-close": _vm.onCancel,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { size: "small" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", "label-width": _vm.formLabelWidth } },
                [_vm._v(" " + _vm._s(_vm.detail.user_name) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "手机号", "label-width": _vm.formLabelWidth },
                },
                [_vm._v(" " + _vm._s(_vm.detail.phone_number) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", "label-width": _vm.formLabelWidth } },
                [_vm._v(" " + _vm._s(_vm.detail.email) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "意向学院",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.collegeName(_vm.detail.college_id)) + " "
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "简历", "label-width": _vm.formLabelWidth } },
                _vm._l(_vm.detail.att_content, function (att, index) {
                  return _c(
                    "el-link",
                    {
                      key: index,
                      attrs: {
                        href: att.url,
                        type: "primary",
                        underline: false,
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", { attrs: { src: _vm.icon_file } }),
                      _vm._v(" " + _vm._s(att.filename) + " "),
                      _c("img", {
                        staticStyle: { "margin-left": "20px" },
                        attrs: { src: _vm.icon_down },
                      }),
                    ]
                  )
                }),
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "简历来源",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.sourceName(_vm.detail.source)) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "简历状态",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.resumeStatus(_vm.detail.status)) + " "
                  ),
                ]
              ),
              _vm.detail.data_detail
                ? [
                    _vm.detail.status >= 5
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "应聘表",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary", underline: false },
                                on: { click: _vm.renderDoc },
                              },
                              [
                                _c("img", { attrs: { src: _vm.icon_file } }),
                                _vm.detail.data_detail.type === 0
                                  ? _c("span", [
                                      _vm._v(
                                        "华南师范大学教师岗位应聘表 - " +
                                          _vm._s(_vm.detail.data_detail.name)
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "华南师范大学青年人才选聘计划书 - " +
                                          _vm._s(_vm.detail.data_detail.name)
                                      ),
                                    ]),
                                _c("img", {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { src: _vm.icon_down },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.detail.status >= 6
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "证明材料及其他附件",
                              "label-width": _vm.formLabelWidth,
                            },
                          },
                          _vm._l(_vm.evidenceAttachs, function (att, index) {
                            return _c(
                              "el-link",
                              {
                                key: index,
                                attrs: {
                                  href: att.url,
                                  type: "primary",
                                  underline: false,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", { attrs: { src: _vm.icon_file } }),
                                _vm._v(" " + _vm._s(att.filename) + " "),
                                _c("img", {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { src: _vm.icon_down },
                                }),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.onCancel } },
                [_vm._v("取 消")]
              ),
              _vm.roles[0].id === 1
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.onHistory },
                    },
                    [_vm._v("历史轨迹")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "历史轨迹",
            visible: _vm.historyVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.historyVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.detail.status_log, size: "mini" } },
            [
              _c(
                "el-table-column",
                { attrs: { property: "phone_number", label: "姓名" } },
                [_vm._v(" " + _vm._s(_vm.detail.user_name) + " ")]
              ),
              _c("el-table-column", {
                attrs: { property: "updated_at", label: "时间" },
              }),
              _c("el-table-column", {
                attrs: { property: "last_status", label: "变更前" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.resumeStatus(scope.row.last_status)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { property: "current_status", label: "变更后" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.resumeStatus(scope.row.current_status)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { property: "admin_name", label: "处理人" },
              }),
              _c("el-table-column", {
                attrs: { property: "refuse_reason", label: "不通过原因" },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.historyVisible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }