var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_c("span", [_vm._v("角色列表")])]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.rolesList,
                fit: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  color: "#909399",
                  background: "#F5F7FA",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "角色 ID", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.id) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "角色名称", width: "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.role_name) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "header-center", label: "角色描述" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.description) + " ")]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "spaceline" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }