/**
 * 常量配置文件
 *
 */

// 上传接口url
const apiEditorUpload = `${process.env.VUE_APP_BASE_API}/admin/weditor/upload`;
const apiAttachUpload = `${process.env.VUE_APP_BASE_API}/admin/upload`;
// 上传文件相关
const configFile = {
  fileupload_ext: "jpg,jpeg,png,doc,docx,xls,xlsx,csv,ppt,pptx,pdf,zip,rar",
  fileupload_size: 10240,
  withCredentials: false, // 富文本编辑器上传文件安全配置
  limit: 10 // 附件上传最大数量
}
export {
  apiEditorUpload,
  apiAttachUpload,
  configFile
}
