const state = {
  visitedViews: [],
  cachedViews: []
}
const mutations = {
  ADD_VISITED_VIEWS_PERSIST: (state, view) => {
    const { path, fullPath, query, meta } = view;
    const tempView = { path, fullPath, query, meta };
    const visitedView = Object.assign({}, tempView, {
      title: view.meta.title || 'no-name',
    });

    const targetViews = state.visitedViews.findIndex(v => v.path === view.path);
    if (targetViews > -1) state.visitedViews.splice(targetViews, 1, visitedView);
    else state.visitedViews.push(visitedView)
  },
  ADD_CACHED_VIEWS_PERSIST: (state, view) => {
    if (state.cachedViews.includes(view.name)) return
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name)
    }
  },

  DEL_VISITED_VIEWS_PERSIST: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1)
        break
      }
    }
  },
  DEL_CACHED_VIEWS_PERSIST: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    index > -1 && state.cachedViews.splice(index, 1)
  },

  FILTER_VISITED_VIEWS_PERSIST: (state, view) => {
    state.visitedViews = state.visitedViews.filter(v => {
      return v.meta.affix || v.path === view.path
    })
  },
  FILTER_CACHED_VIEWS_PERSIST: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1)
    } else {
      // if index = -1, there is no cached tags
      state.cachedViews = []
    }
  },

  UPGRADE_VISITED_VIEWS_PERSIST: state => {
    // keep affix tags
    const affixTags = state.visitedViews.filter(tag => tag.meta.affix)
    state.visitedViews = affixTags
  },
  UPDATE_CACHED_VIEWS_PERSIST: state => {
    state.cachedViews = []
  },

  UPDATE_VISITED_VIEWS_PERSIST: (state, view) => {
    for (let v of state.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view)
        break
      }
    }
  }
}

const actions = {
  addView({ dispatch }, view) {
    dispatch('addVisitedView', view)
    dispatch('addCachedView', view)
  },
  addVisitedView({ commit }, view) {
    commit('ADD_VISITED_VIEWS_PERSIST', view)
  },
  addCachedView({ commit }, view) {
    commit('ADD_CACHED_VIEWS_PERSIST', view)
  },

  delView({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delVisitedView', view)
      dispatch('delCachedView', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delVisitedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_VISITED_VIEWS_PERSIST', view)
      resolve([...state.visitedViews])
    })
  },
  delCachedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_CACHED_VIEWS_PERSIST', view)
      resolve([...state.cachedViews])
    })
  },

  delOthersViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delOthersVisitedViews', view)
      dispatch('delOthersCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delOthersVisitedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('FILTER_VISITED_VIEWS_PERSIST', view)
      resolve([...state.visitedViews])
    })
  },
  delOthersCachedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('FILTER_CACHED_VIEWS_PERSIST', view)
      resolve([...state.cachedViews])
    })
  },

  delAllViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delAllVisitedViews', view)
      dispatch('delAllCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delAllVisitedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('UPGRADE_VISITED_VIEWS_PERSIST')
      resolve([...state.visitedViews])
    })
  },
  delAllCachedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('UPDATE_CACHED_VIEWS_PERSIST')
      resolve([...state.cachedViews])
    })
  },

  updateVisitedView({ commit }, view) {
    commit('UPDATE_VISITED_VIEWS_PERSIST', view)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
