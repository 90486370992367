<template>
  <div class="app-container">
    <el-card v-loading="loading" class="box-card">
      <div slot="header" class="clearfix">
        <span>编辑管理员</span>
        <el-button
          style="float: right; padding: 0; margin-left: 14px"
          type="text"
          @click="$emit('closeDrawer')"
        >
          返回
        </el-button>
        <el-button
          style="float: right; padding: 0"
          type="text"
          @click="onSubmit('form')"
        >
          保存
        </el-button>
      </div>
      <el-form
        ref="form"
        class="common-form"
        :model="form"
        label-width="100px"
        :rules="rules"
        :inline-message="true"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" autocomplete="off" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email" />
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input v-model="form.account" autocomplete="off" />
        </el-form-item>
        <el-form-item label="关联ID" prop="oa_id">
          <el-input v-model="form.oa_id" autocomplete="off" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" show-password placeholder="默认代表使用原密码" autocomplete="new-password" />
        </el-form-item>
        <el-form-item label="角色" prop="role_id">
          <el-select
            v-model="form.role_id"
            value-key="id"
            multiple
            filterable
            disabled
            default-first-option
            placeholder="请选择角色"
            :multiple-limit="5"
          >
            <el-option
              v-for="item in rolelist"
              :key="item.id"
              :label="item.role_name"
              :value="item.id"
              :disabled="item.disabled"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="学院" prop="college_id">
          <el-select
            v-model="form.college_id"
            filterable
            multiple
            disabled
            default-first-option
            placeholder="请选择学院"
          >
            <el-option
              v-for="item in collegelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="onSubmit('form')">保存</el-button>
          <el-button @click="$emit('closeDrawer')">返回</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { updateUser, getRoles, getUserDetail } from "@/api/system_setting";
import { mapGetters } from 'vuex'

export default {
  props: ['id'],
  data() {
    return {
      loading: false,
      rolelist: [],
      form: {},
      disableSubmit: false,
      rules: {
        name: [
          {
            max: 15,
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            message: "请输入正确的手机号",
            trigger: "blur",
            pattern: /^1[3-9][0-9]{9}$/
          }
        ],
        email: [
          {
            type: "email",
            message: "请输入邮箱",
            trigger: "blur",
            pattern: /^([a-zA-Z]|[0-9])(\w|\-|\.)+@(([a-zA-Z0-9]|\-|\.)*([a-zA-Z0-9]|\-))+\.[a-zA-Z]{2,}$/
          }
        ],
        role_id: [{ required: true, message: "请选择角色", trigger: "change" }],
        college_id: [{ required: true, message: "请选择学院", trigger: "change" }],
        account: [
          {
            required: true,
            message: "请输入登录名",
            trigger: "blur"
          },
          {
            max: 15,
            message: "长度在 1 到 15 个字符",
            trigger: "blur"
          }
        ],
        password: [
          {
            max: 15,
            message: "长度在 1 到 15 个字符",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(['collegelist'])
  },
  created() {
    this.getUserDetail();
  },
  methods: {
    async getUserDetail() {
      this.loading = true;
      // 获取角色列表
      if (this.rolelist.length === 0) {
        const { data } = await getRoles({});
        this.rolelist = data.list.map((item) => {
          return {
            ...item,
            disabled: item.id === 1
          };
        });
      }

      // 获取用户详情
      const userId = this.id;
      const userResponse = await getUserDetail(userId);
      const { id, account, name, phone, email, roles, oa_id, college_id } = userResponse.data;
      console.log("college_id", college_id)

      // 匹配角色
      const userRoles = roles.map((item) => {
        return item.id;
      });

      // 设置表单回填
      this.form = {
        id,
        name,
        phone,
        email,
        account,
        password: "",
        role_id: userRoles,
        oa_id,
        college_id: [college_id]
      };
      this.loading = false;
    },
    onSubmit(formName) {
      const updateData = {
        ...this.form,
        college_id: this.form.college_id[0]
      };
      // 如果密码为空，则不下发密码
      !updateData.password && delete updateData.password;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disableSubmit = true;
          updateUser(this.form.id, updateData)
            .then((response) => {
              if (response.httpCode === 200 && response.status === 'success') {
                this.$message.success(`更新成功`);
                this.$emit('closeDrawer') // 关闭抽屉
                this.$emit('refreshList') // 刷新列表数据
                this.disableSubmit = false;
                return true;
              } else {
                this.disableSubmit = false;
                this.$message.error(response.message);
                return false;
              }
            })
            .catch(() => {
              this.disableSubmit = false;
              return false;
            });
        } else {
          return false;
        }
      });
    },
    goto(target) {
      this.$router.push(target);
    }
  }
};
</script>
<style lang="scss" scoped>
.app-container {
  .el-input {
      width: 450px;
  }
  .el-select{
    width: 450px;
  }
}
</style>
