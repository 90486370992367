var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "分发",
        visible: _vm.visible,
        "before-close": _vm.onCancel,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, size: "small" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "姓名",
                "label-width": _vm.formLabelWidth,
                required: true,
              },
            },
            [_vm._v(" " + _vm._s(_vm.detail.user_name) + " ")]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "手机号",
                "label-width": _vm.formLabelWidth,
                required: true,
              },
            },
            [_vm._v(" " + _vm._s(_vm.detail.phone_number) + " ")]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "邮箱",
                "label-width": _vm.formLabelWidth,
                required: true,
              },
            },
            [_vm._v(" " + _vm._s(_vm.detail.email) + " ")]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "意向学院", "label-width": _vm.formLabelWidth } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.getCollegeName(_vm.detail.college_id) || "-") +
                  " "
              ),
            ]
          ),
          _vm.roles[0].id === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分发学院",
                    "label-width": _vm.formLabelWidth,
                    prop: "college_id",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "default-first-option": "",
                        clearable: "",
                        placeholder: "请选择学院",
                        "value-key": "id",
                      },
                      on: { change: _vm.changeCollege },
                      model: {
                        value: _vm.form.college_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "college_id", $$v)
                        },
                        expression: "form.college_id",
                      },
                    },
                    _vm._l(_vm.collegeAdminList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-form-item",
                {
                  attrs: {
                    label: "学院",
                    required: true,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.getCollegeNameById(_vm.collegeId)) + " "
                  ),
                ]
              ),
          _vm.roles[0].id === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分发给",
                    prop: "assigned_college_admin_id",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "default-first-option": "",
                        clearable: "",
                        placeholder: "请选择学院管理员",
                      },
                      model: {
                        value: _vm.form.assigned_college_admin_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "assigned_college_admin_id", $$v)
                        },
                        expression: "form.assigned_college_admin_id",
                      },
                    },
                    _vm._l(_vm.collegeAdminChild, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.onCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                type: "primary",
                disabled: _vm.disableSubmit,
              },
              on: {
                click: function ($event) {
                  return _vm.onConfirm("form")
                },
              },
            },
            [_vm._v("下发")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }