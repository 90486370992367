var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑简历",
        visible: _vm.visible,
        "before-close": _vm.onCancel,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, size: "small" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "姓名",
                "label-width": _vm.formLabelWidth,
                prop: "user_name",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.user_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "user_name", $$v)
                  },
                  expression: "form.user_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "手机号",
                "label-width": _vm.formLabelWidth,
                prop: "phone_number",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.phone_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phone_number", $$v)
                  },
                  expression: "form.phone_number",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "邮箱",
                "label-width": _vm.formLabelWidth,
                prop: "email",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
            ],
            1
          ),
          _vm.roles[0].id === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "意向学院",
                    "label-width": _vm.formLabelWidth,
                    prop: "college_id",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "default-first-option": "",
                        clearable: "",
                        placeholder: "请选择学院",
                      },
                      model: {
                        value: _vm.form.college_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "college_id", $$v)
                        },
                        expression: "form.college_id",
                      },
                    },
                    _vm._l(_vm.collegelist, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "学院", "label-width": _vm.formLabelWidth } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.collegeName(_vm.form.college_id)) + " "
                  ),
                ]
              ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "简历",
                required: true,
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action: _vm.apiAttachUpload,
                    headers: _vm.headers,
                    "on-success": _vm.handleAttachSuccess,
                    "on-remove": _vm.handleRemove,
                    "before-upload": _vm.beforeAttachUpload,
                    "file-list": _vm.form.att_content,
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("点击上传")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.onCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                type: "primary",
                disabled: _vm.disableSubmit,
              },
              on: {
                click: function ($event) {
                  return _vm.onConfirm("form")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }