<template>
  <div class="app-container">
    <el-card class="box-card">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" size="mini">
        <el-row type="flex" justify="start" :gutter="10">
          <el-col :span="8"><el-form-item label="所属分类">
            <el-cascader
              v-model="searchForm.category_id"
              style="width:200px"
              placeholder="顶级"
              :options="categories"
              :show-all-levels="false"
              :props="{ checkStrictly: true,label:'name',value:'id',emitPath:false }"
              popper-class="cancelRadio"
              clearable
            />
          </el-form-item></el-col>
          <el-col :span="12"><el-form-item label="文档标题">
            <el-input v-model="searchForm.title" placeholder="可输入标题，支持模糊查询" style="width:350px" />
          </el-form-item></el-col>
        </el-row>
        <el-row type="flex" justify="start" :gutter="10">
          <el-col :span="8"><el-form-item label="是否显示">
            <el-select v-model="searchForm.is_show" placeholder="是否显示" clearable style="width:200px">
              <el-option label="显示" value="1" />
              <el-option label="不显示" value="0" />
            </el-select>
          </el-form-item></el-col>
          <el-col :span="12"> <el-form-item label="发布日期">
            <el-date-picker
              v-model="searchForm.dateArr"
              clearable
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item></el-col>
          <el-col :span="4"><el-button size="mini" type="primary" @click="search">查询</el-button></el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <el-row type="flex" justify="start">
        <el-button icon="el-icon-plus" size="mini" type="primary" @click="onPublish">发布内容</el-button>
        <el-button icon="el-icon-delete" size="mini" type="danger" @click="onDelete">批量删除</el-button>
      </el-row>

      <el-table
        ref="multipleTable"
        v-loading="table_loading"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        />

        <el-table-column
          prop="title"
          label="标题"
          show-overflow-tooltip
        />
        <el-table-column
          prop="category_name"
          label="所属分类"
        />
        <el-table-column
          prop="click_num"
          label="点击数"
          show-overflow-tooltip
        />
        <el-table-column
          prop="publisher_name"
          label="来源"
          show-overflow-tooltip
        />
        <el-table-column
          prop="publish_time"
          label="发布日期"
          width="120"
        >
          <template slot-scope="scope">{{ scope.row.publish_time | timeFilter }}</template>
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序"
          show-overflow-tooltip
        />
        <el-table-column
          label="是否显示"
          show-overflow-tooltip
        > <template slot-scope="scope">
          {{ scope.row.is_show===1?'显示':'不显示' }}
        </template>
        </el-table-column>
        <el-table-column
          label="是否置顶"
          show-overflow-tooltip
        > <template slot-scope="scope">
          {{ scope.row.is_topping===1?'置顶':'不置顶' }}
        </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="spaceline" />
      <el-row :gutter="20">
        <el-col :span="16" :offset="8" style="text-align: right">
          <el-pagination
            background
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
    </el-card>
    <!--新增，编辑抽屉-->
    <el-drawer
      ref="drawer"
      :visible.sync="drawer"
      :with-header="false"
      :destroy-on-close="true"
      direction="rtl"
      size="90%"
    >
      <editArticle v-if="isEdit" :id="articleId" @closeDrawer="closeDrawer" @refreshList="refreshList" />
      <addArticle v-else @closeDrawer="closeDrawer" @refreshList="refreshList" />
    </el-drawer>
  </div>
</template>
<script>
import { parseTime } from "@/utils/index";
import { getArticleList, deleteArticle, getCategoryList } from "@/api/content-manage";
import addArticle from './components/add.vue'
import editArticle from './components/edit.vue'
export default {
  components: {
    addArticle,
    editArticle
  },
  filters: {
    timeFilter(timestamp) {
      return parseTime(timestamp, "{y}-{m}-{d}");
    },
  },
  data() {
    return {
      searchForm: {
        category_id: [],
        // title: '',
        // is_show: '',
      },
      categories: [],
      tableData: [],
      multipleSelection: [], // 选中的list
      dialogTableVisible: false,
      // 分页
      total: 0,
      currentPage: 1,
      pagesize: 10,
      // 抽屉状态
      drawer: false,
      // 是否编辑
      isEdit: false,
      // 当前编辑的文章id
      articleId: '',
    }
  },
  created() {
    this.initPage()
  },
  methods: {
    search() { // 点击查询
      this.getArticleList()
    },
    onPublish() {
      this.isEdit = false
      this.drawer = true
      this.articleId = ''
    },
    onDelete() {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const ids = this.multipleSelection.map(item => item.id);
          deleteArticle({ id: ids })
            .then(res => {
              if (res.httpCode === 200 && res.statusCode === 0) {
                this.$message({
                  message: '文档删除成功',
                  type: 'success'
                });
                this.getArticleList()
              }
            }).catch(() => {
              this.$message.error('删除文档发生错误')
            })
        }).catch(() => {})
      } else {
        this.$message({
          message: '请先选择要批量删除的项目',
          type: 'warning'
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit(row) {
      this.isEdit = true
      this.drawer = true
      this.articleId = row.id
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 这里是删除接口
        deleteArticle({ id: [row.id] })
          .then(res => {
            if (res.httpCode === 200 && res.statusCode === 0) {
              this.$message({
                message: '删除文档成功',
                type: 'success'
              });
              this.getArticleList()
            }
          }).catch(() => {
            this.$message.error('删除文档发生错误')
          })
        console.log(row.id)
      }).catch(() => {})
    },
    // modifyState(row) {
    //   const id = row.id
    //   const is_show = row.is_show // 这里取到的是新状态的值
    //   const params = {
    //     id,
    //     is_show
    //   }
    //   isShowArticle(params)
    //     .then(res => {
    //       if (res.httpCode === 200) {
    //         this.$message({
    //           message: '状态更新成功',
    //           type: 'success'
    //         });
    //         this.getArticleList()
    //       }
    //     }).catch(() => {
    //       this.$message.error('状态更新发生错误')
    //     })
    // },
    handleSizeChange(val) {
      this.pagesize = val
      this.getArticleList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getArticleList()
    },
    // 设置分类，如果是链接/频道类型禁止选择
    setCategoryList(list) {
      list.forEach(item => {
        if (item.type !== 2) { // 菜单类型为跳转链接或者频道不能搜索
          item['disabled'] = true;
        }
        if (item.children && item.children.length > 0) {
          this.setCategoryList(item.children);
        }
      })
      return list;
    },
    initPage() {
      // 获取分类列表
      getCategoryList().then(res => {
        if (res.httpCode === 200) {
          this.categories = this.setCategoryList(res.data)
        }
      }).catch(() => {
        this.$message.error('请求分类列表发生错误')
      })
      // 默认加载第一页列表数据
      this.getArticleList()
    },
    // 请求列表数据
    getArticleList() {
      this.table_loading = true
      const publish_start_time = this.searchForm.dateArr && this.searchForm.dateArr[0]
      const publish_end_time = this.searchForm.dateArr && this.searchForm.dateArr[1]
      const param = {
        page: this.currentPage,
        size: this.pagesize,
        ...this.searchForm,
        publish_start_time,
        publish_end_time
      }
      console.log(param)
      getArticleList(param)
        .then(res => {
          this.tableData = res.httpCode === 200 ? res.data.list : []
          this.table_loading = false
          this.total = res.httpCode === 200 ? res.data.meta.total : []
        })
        .catch(() => {
          this.$message.error('请求列表发生错误')
          this.table_loading = false
        })
    },
    closeDrawer() {
      this.$refs.drawer.closeDrawer()
      this.drawer = false
    },
    refreshList() {
      this.getArticleList()
    },
    goto(target) {
      this.$router.push(target)
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  .box-card{
    margin-top: 10px;
  }
  .el-date-editor{
    width: 350px;
  }
  ::v-deep {
    .el-drawer__body{
      overflow-y: scroll
    }
  }
}
</style>
