<template>
  <div class="app-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>添加内容</span>
        <el-button
          style="float: right; padding: 0;margin-left:14px"
          type="text"
          @click="$emit('closeDrawer')"
        >
          返回
        </el-button>
        <el-button
          style="float: right; padding: 0;"
          type="text"
          :disabled="issubmit"
          @click="onSubmit('form')"
        >
          保存
        </el-button>
      </div>
      <el-form
        ref="form"
        class="common-form"
        :model="form"
        label-width="80px"
        :rules="rules"
        :inline-message="true"
      >
        <el-form-item label="内容标题" prop="title">
          <el-input v-model="form.title" />
        </el-form-item>
        <el-form-item label="内容分类" prop="category_id">
          <el-cascader
            v-model="form.category_id"
            placeholder="请选择分类"
            :options="categories"
            :show-all-levels="false"
            :props="{ checkStrictly: true,label:'name',value:'id',emitPath:false }"
            popper-class="cancelRadio"
            clearable
          />
        </el-form-item>
        <el-form-item label="摘要" prop="introduction">
          <el-input v-model="form.introduction" :autosize="{ minRows: 4, maxRows: 8}" type="textarea" />
        </el-form-item>
        <el-form-item label="缩略图">
          <el-upload
            class="thumb-uploader"
            :action="apiAttachUpload"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleThumbSuccess"
            :before-upload="beforeThumbUpload"
          >
            <img v-if="form.thumbnail" :src="form.thumbnail" class="thumb">
            <i v-else class="el-icon-plus thumb-uploader-icon" />
          </el-upload>
        </el-form-item>
        <el-form-item label="内容" required prop="content">
          <div style="border: 1px solid #ccc;">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :default-config="toolbarConfig"
            />
            <Editor
              v-model="form.content"
              style="height: 400px; overflow-y: hidden;"
              @onCreated="onCreated"
            />
          </div>
        </el-form-item>
        <el-form-item label="附件" prop="att_content">
          <el-upload
            class="upload-demo"
            :action="apiAttachUpload"
            :headers="headers"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :file-list="form.att_content"
            :on-success="handleAttachSuccess"
            :before-upload="beforeAttachUpload"
            :limit="configFile.limit"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传{{ configFile.fileupload_ext }}文件，且不超过{{ configFile.fileupload_size }}KB</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否显示">
          <el-switch v-model="form.is_show" />
        </el-form-item>
        <el-form-item label="是否置顶">
          <el-switch v-model="form.is_topping" />
        </el-form-item>
        <el-form-item label="是否投递">
          <el-switch v-model="form.is_allow_delivery" />
        </el-form-item>
        <el-form-item label="发布日期">
          <el-date-picker
            v-model="form.publish_time"
            type="datetime"
            placeholder="请选择发布日期"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
          />
        </el-form-item>
        <el-form-item label="截止日期">
          <el-date-picker
            v-model="form.end_time"
            type="datetime"
            placeholder="请选择截止日期"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
          />
        </el-form-item>
        <el-form-item label="点击数" prop="click_count">
          <el-input v-model.number="form.click_count" maxlength="14" />
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model.number="form.sort" maxlength="14" />
        </el-form-item>
        <el-form-item label="来源" prop="publisher_name">
          <el-input v-model="form.publisher_name" />
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" :disabled="issubmit" @click="onSubmit('form')">保存</el-button>
          <el-button @click="$emit('closeDrawer')">返回</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { apiEditorUpload, apiAttachUpload, configFile } from '@/utils/constant'
import { addArticle, getCategoryList } from "@/api/content-manage";
import { parseTime } from "@/utils/index";
import { mapGetters } from 'vuex'
export default {
  components: { Editor, Toolbar },
  data() {
    return {
      issubmit: false,
      editor: null,
      toolbarConfig: {
        excludeKeys: [
          'fullScreen'
        ]
      },
      editorAttachUpload: apiEditorUpload, // 富文本上传接口
      apiAttachUpload: apiAttachUpload, // 通用上传接口,
      configFile: configFile, // 文件上传配置，比如格式，大小
      headers: { Authorization: getToken() },
      categories: [],
      form: {
        title: '',
        category_id: '',
        introduction: '',
        content: '',
        att_content: [],
        thumbnail: '',
        is_show: true,
        click_count: 0,
        sort: 0,
        publisher_name: "",
        publish_time: parseTime(new Date().getTime(), "{y}-{m}-{d} {h}:{i}"),
        end_time: parseTime((new Date().getTime() + 31536000000), "{y}-{m}-{d} {h}:{i}"),
        is_allow_delivery: true,
        is_topping: false
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          },
          {
            max: 100,
            message: '长度在 1 到 100 个字符',
            trigger: 'blur'
          }
        ],
        category_id: [
          {
            required: true,
            message: '请选择分类',
            trigger: 'change'
          }
        ],
        click_count: [
          {
            type: 'number',
            message: '必须为数字',
            trigger: 'blur'
          }
        ],
        sort: [
          {
            type: 'number',
            message: '排序必须为数字',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  computed: {
    ...mapGetters(['name'])
  },
  mounted() {
    this.form.publisher_name = this.name
  },
  created() {
    // 获取分类列表
    getCategoryList().then(res => {
      if (res.httpCode === 200 && res.statusCode === 0) {
        this.categories = this.setCategoryList(res.data)
      }
    }).catch(() => {
      this.$message.error('请求分类列表发生错误')
    })
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      this.editor.getMenuConfig('uploadImage').headers = {
        Authorization: getToken()
      }
      this.editor.getMenuConfig('uploadImage').withCredentials = this.configFile.withCredentials
      this.editor.getMenuConfig('uploadImage').server = this.editorAttachUpload
      this.editor.getMenuConfig('uploadImage').fieldName = 'file'
      this.editor.getMenuConfig('uploadImage').maxFileSize = this.configFile.fileupload_size * 1024

      this.editor.getMenuConfig('uploadVideo').headers = {
        Authorization: getToken()
      }
      this.editor.getMenuConfig('uploadVideo').withCredentials = this.configFile.withCredentials
      this.editor.getMenuConfig('uploadVideo').server = this.editorAttachUpload
      this.editor.getMenuConfig('uploadVideo').fieldName = 'file'
      this.editor.getMenuConfig('uploadVideo').maxFileSize = this.configFile.fileupload_size * 1024
    },
    handleRemove(file, fileList) {
      const index = this.form.att_content.findIndex((item) => item.url === file.url)
      if (index > -1) {
        this.form.att_content.splice(index, 1)
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleAttachSuccess(res, file) {
      if (res.httpCode === 200 && res.statusCode === 0) {
        const info = { filename: res.data.file_name, name: res.data.file_name, url: res.data.url, filesize: res.data.size }
        this.form.att_content.push(info)
      } else {
        this.$message.error(res.message)
        return false
      }
    },
    beforeAttachUpload(file) {
      const configFileExtArr = configFile.fileupload_ext
      const filename_arr = file.name.split('.')
      const filetype = filename_arr[filename_arr.length - 1].toLowerCase()
      if (!configFileExtArr.includes(filetype)) {
        this.$message.error('上传文件格式不允许')
        return false
      }
      if (file.size / 1024 > configFile.fileupload_size) {
        this.$message.error(`上传文件最大为${configFile.fileupload_size}kb`)
        return false
      }
      return true
    },
    onSubmit(formName) {
      const that = this
      that.issubmit = true
      this.form.content = this.editor.getHtml()
      const insertData = { ...this.form }
      this.$refs[formName].validate(valid => {
        if (valid) {
          insertData.is_show = insertData.is_show === true ? 1 : 0
          insertData.is_allow_delivery = insertData.is_allow_delivery === true ? 1 : 0
          insertData.is_topping = insertData.is_topping === true ? 1 : 0
          addArticle(insertData).then(res => {
            if (res.httpCode === 200 && res.statusCode === 0) {
              this.$message({
                message: '内容发布成功',
                type: 'success'
              });
              this.$emit('closeDrawer') // 关闭抽屉
              this.$emit('refreshList') // 刷新列表数据
              that.issubmit = false
            } else {
              this.$message.error(res.message)
            }
          }).catch(() => {
            this.$message.error('内容发布失败')
          })
        } else {
          that.issubmit = false
          return false
        }
      })
    },
    handleThumbSuccess(res, file) {
      if (res.httpCode === 200 && res.statusCode === 0) {
        this.form.thumbnail = res.data.url
      } else {
        this.$message.error(res.message)
        return false
      }
    },
    beforeThumbUpload(file) {
      const filetypeArr = file.type.split('/')
      const filetype = filetypeArr[1].toLowerCase()
      const configFileExtArr = configFile.fileupload_ext.split(',')

      if (!configFileExtArr.includes(filetype)) {
        this.$message.error('上传文件格式不允许')
        return false
      }
      if (file.size / 1024 > configFile.fileupload_size) {
        this.$message.error(`上传文件最大为${configFile.fileupload_size}kb`)
        return false
      }
      return true
    },
    // 设置分类，如果是链接/频道类型禁止选择
    setCategoryList(list) {
      list.forEach(item => {
        if (item.type !== 2) { // 菜单类型为跳转链接或者频道不能搜索
          item['disabled'] = true;
        }
        if (item.children && item.children.length > 0) {
          this.setCategoryList(item.children);
        }
      })
      return list;
    },
    goto(target) {
      this.$router.push(target)
    }
  }
}
</script>
<style scoped>
.thumb-uploader {
  display: inline-block;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 122px;
  height: 122px;
}
.thumb-uploader img {
  width: 120px;
  height: 120px;
  border-radius: 6px;
}
.thumb-uploader:hover {
  border-color: #409eff;
}
.thumb-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.el-textarea,
.el-select,
.el-input,
.el-date-editor {
  width: 450px;
}
.el-cascader{
    width: 450px;
  }
</style>
