<template>
  <div class="app-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>分类管理</span>
      </div>
      <el-table
        v-loading="listLoading"
        :data="categories"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <el-table-column
          prop="name"
          label="分类"
          sortable
        />
        <el-table-column
          label="分类等级"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.level === 0">一级分类</el-tag>
            <el-tag v-else-if="scope.row.level === 1" type="success">二级分类</el-tag>
            <el-tag v-else type="warning">三级分类</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="类型"
        >
          <template slot-scope="scope">{{ getTypeNameById(scope.row.type) }}</template>
        </el-table-column>
        <el-table-column
          prop="model"
          label="模型"
        >
          <template slot-scope="scope">{{ getModelNameById(scope.row.template_id) }}</template>
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序"
        />
        <el-table-column
          prop="is_show"
          label="导航显示"
        >
          <template slot-scope="scope">
            {{ scope.row.is_show===1?'显示':'不显示' }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="相关操作">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              @click="funEdit(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="small"
              type="danger"
              @click="funDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="spaceline" />
      <el-row :gutter="20">
        <el-col :span="8">
          <el-button size="small" type="primary" @click="funAdd()">
            添加
          </el-button>
        </el-col>
      </el-row>
    </el-card>
    <!-- 编辑分类/添加分类对话框 -->
    <el-dialog :title="dialogType === 'edit' ? '编辑分类' : '添加分类'" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
      <el-form
        ref="form"
        class="common-form"
        :model="form"
        label-width="100px"
        :rules="rules"
        :inline-message="true"
      >
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">频道</el-radio>
            <el-radio :label="2">列表</el-radio>
            <el-radio :label="3">跳转</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.type!==3" label="模型" prop="template_id">
          <el-select v-model="form.template_id" placeholder="请选择">
            <el-option
              v-for="item in modelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所属分类" prop="pid">
          <el-cascader
            v-model="form.pid"
            :disabled="dialogType === 'edit'"
            placeholder="顶级"
            :options="categories"
            :show-all-levels="false"
            :props="{ checkStrictly: true,label:'name',value:'id',emitPath:false }"
            popper-class="cancelRadio"
            clearable
            @change="changecategory"
          />
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" class="small" />
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model.number="form.sort" class="mini" maxlength="14" />
        </el-form-item>
        <el-form-item v-if="form.type===3" label="跳转URL">
          <el-input v-model="form.link" class="mini" placeholder="类型为跳转链接时填写" />
        </el-form-item>
        <el-form-item label="导航显示">
          <el-switch
            v-model="form.is_show"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="funSave('form')">保存</el-button>
          <el-button @click="dialogTableVisible=false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { parseTime } from "@/utils/index";
import { deleteCategory, updateCategory, addCategory, getCategoryList } from "@/api/content-manage";
export default {
  filters: {
    timeFilter(timestamp) {
      return parseTime(timestamp, "{y}-{m}-{d} {h}:{i}");
    },
  },
  data() {
    return {
      form: {
        type: 2, // 类型 1-频道封面 2-栏目列表 3-跳转链接
        pid: 0,
        name: '',
        sort: 0,
        link: '',
        is_show: 1, // 是否导航显示
        template_id: 1 // 模型id
      },
      categories: [],
      modelList: [], // 模型列表
      typeList: [], // 类型列表
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' },
        ],
        template_id: [
          { required: true, message: '请选择模型', trigger: 'blur' },
        ],
        sort: [
          { type: 'number', message: '排序必须为数字', trigger: 'blur' }
        ]
      },
      listLoading: false,
      dialogTableVisible: false,
      dialogType: '',
    };
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.listLoading = true;
      // 获取分类列表
      getCategoryList().then(res => {
        if (res.httpCode === 200 && res.statusCode === 0) {
          this.categories = res.data
          this.listLoading = false;
        }
      }).catch(() => {
        this.$message.error('请求分类列表发生错误')
        this.listLoading = false;
      })
      // 获取模型列表
      this.modelList = [{
        id: 1,
        name: '新闻公告'
      }, {
        id: 2,
        name: '图文相册'
      }, {
        id: 3,
        name: '专题活动'
      }, {
        id: 4,
        name: '服务指南'
      }]
      // 获取类型列表
      this.typeList = [{
        id: 1,
        name: '频道'
      }, {
        id: 2,
        name: '列表'
      }, {
        id: 3,
        name: '跳转'
      }]
    },
    // 选择父级节点时出发
    changecategory(pid) {
      if (pid === null) { // 清空父级节点后，默认为顶级分类
        this.form.pid = 0
      }
    },
    getModelNameById(id) { // 根据id获取模型名称
      const arr = this.modelList.filter(item => { return item.id === id })
      return arr[0] && arr[0].name
    },
    getTypeNameById(id) { // 根据id获取类型名称
      const arr = this.typeList.filter(item => { return item.id === id })
      return arr[0] && arr[0].name
    },
    funAdd() {
      this.dialogType = 'add';
      this.dialogTableVisible = true;
      this.form = {
        type: 2,
        pid: 0,
        name: '',
        sort: 0,
        is_show: 1,
      }
    },
    funEdit(index, row) {
      this.dialogType = 'edit';
      this.dialogTableVisible = true;
      this.form.type = row.type || 2;
      this.form.id = row.id;
      this.form.pid = row.pid;
      this.form.name = row.name;
      this.form.is_show = row.is_show;
      this.form.link = row.link;
      this.form.sort = row.sort;
    },
    funDelete(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const param = {
          id: row.id,
        };
        deleteCategory(param).then(res => {
          if (res.httpCode === 200 && res.statusCode === 0) {
            this.$message({
              message: '分类删除成功',
              type: 'success'
            });
            this.initPage();
            this.dialogTableVisible = false
          } else {
            this.$message.error(res.message)
          }
        }).catch(() => {
          this.$message.error('删除分类发生错误')
        })
      }).catch(() => {});
    },
    funSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = this.form
          if (this.dialogType === 'edit') {
            updateCategory(params).then(res => {
              if (res.httpCode === 200 && res.statusCode === 0) {
                this.$message({
                  message: '分类修改成功',
                  type: 'success'
                });
                this.initPage();
                this.dialogTableVisible = false
              } else {
                this.$message.error(res.message)
              }
            }).catch(() => {
              this.$message.error('修改分类发生错误')
            })
          } else {
            addCategory(params).then(res => {
              if (res.httpCode === 200 && res.statusCode === 0) {
                this.$message({
                  message: '分类添加成功',
                  type: 'success'
                });
                this.initPage()
                this.dialogTableVisible = false
              } else {
                this.$message.error(res.message)
              }
            }).catch(() => {
              this.$message.error('添加分类发生错误')
            })
          }
          console.log("调用保存接口：参数为", this.form)
        } else {
          return false
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  .el-input {
      width: 200px;
  }
  .el-select{
    width: 200px;
  }
  .el-cascader{
    width: 200px;
  }
}
</style>
