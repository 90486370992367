import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use route-path to determine if the current user has permission
 * return the matched permission object
 * @param roles
 * @param route
 */
function hasPermission(permissions, route) {
  return permissions.find(item => {
    return item['front_path'] === route.path
  });
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, permissions) {
  const res = []

  routes.forEach(route => {
    const routerTmp = { ...route }
    const permissionTmp = [...permissions]
    const matchedPermission = hasPermission(permissionTmp, routerTmp)
    if (matchedPermission) {
      if (routerTmp.children) {
        routerTmp.children = filterAsyncRoutes(routerTmp.children, matchedPermission.children)
      }
      res.push(routerTmp)
    }
  })
  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, permissionList) {
    return new Promise(resolve => {
      let accessedRoutes
      if (!permissionList || permissionList.length === 0) {
        accessedRoutes = [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, permissionList)
      }

      // 404 page must be placed at the end !!!
      accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })

      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
