var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("编辑管理员")]),
              _c(
                "el-button",
                {
                  staticStyle: {
                    float: "right",
                    padding: "0",
                    "margin-left": "14px",
                  },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeDrawer")
                    },
                  },
                },
                [_vm._v(" 返回 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", padding: "0" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit("form")
                    },
                  },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                rules: _vm.rules,
                "inline-message": true,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", prop: "email" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号", prop: "account" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.account,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "account", $$v)
                      },
                      expression: "form.account",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联ID", prop: "oa_id" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.oa_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "oa_id", $$v)
                      },
                      expression: "form.oa_id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: {
                      "show-password": "",
                      placeholder: "默认代表使用原密码",
                      autocomplete: "new-password",
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色", prop: "role_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "value-key": "id",
                        multiple: "",
                        filterable: "",
                        disabled: "",
                        "default-first-option": "",
                        placeholder: "请选择角色",
                        "multiple-limit": 5,
                      },
                      model: {
                        value: _vm.form.role_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "role_id", $$v)
                        },
                        expression: "form.role_id",
                      },
                    },
                    _vm._l(_vm.rolelist, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.role_name,
                          value: item.id,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "学院", prop: "college_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        multiple: "",
                        disabled: "",
                        "default-first-option": "",
                        placeholder: "请选择学院",
                      },
                      model: {
                        value: _vm.form.college_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "college_id", $$v)
                        },
                        expression: "form.college_id",
                      },
                    },
                    _vm._l(_vm.collegelist, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit("form")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeDrawer")
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }