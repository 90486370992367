<template>
  <div class="app-container">
    <el-card class="box-card">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" size="mini">
        <el-row type="flex" justify="start" :gutter="20" align="middle">
          <el-col :span="6"><el-form-item label="姓名">
            <el-input v-model="searchForm.user_name" placeholder="姓名" style="width:200px" clearable />
          </el-form-item></el-col>
          <el-col :span="7"><el-form-item label="简历状态">
            <el-select v-model="searchForm.status" clearable placeholder="简历状态" style="width:200px">
              <el-option
                v-for="item in getStatusList()"
                :key="item.id"
                :label="item.name"
                :value="item.status"
              />
            </el-select>
          </el-form-item></el-col>
          <el-col :span="7"><el-form-item label="简历来源">
            <el-select v-model="searchForm.source" clearable placeholder="简历来源" style="width:200px">
              <el-option
                v-for="item in sourcelist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item></el-col>
          <el-col :span="4"><el-button type="primary" size="small" @click="search">查询</el-button></el-col>
        </el-row>
        <el-row />
      </el-form>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-row :gutter="20" type="flex" align="middle" justify="space-between">
          <el-col :span="8">
            <span>已处理简历列表</span>
          </el-col>
        </el-row>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="table_loading"
        :data="tableList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          color: '#909399',
          background: '#F5F7FA',
        }"
      >
        <el-table-column
          label="姓名"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.user_name }}</template>
        </el-table-column>
        <!-- <el-table-column
          prop="college_name"
          label="分发学院"
          show-overflow-tooltip
        /> -->
        <el-table-column
          prop="phone_number"
          label="手机号"
          show-overflow-tooltip
        />
        <el-table-column
          prop="email"
          label="邮箱"
          show-overflow-tooltip
        />
        <el-table-column
          prop="delivery_time"
          label="创建时间"
          show-overflow-tooltip
        />
        <el-table-column
          prop="source"
          label="简历来源"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              plain
              disabled
            >{{ sourceName(scope.row.source) }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="source"
          label="状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              plain
              disabled
              :style="getStyle(scope.row.status)"
            >{{ resumeState(scope.row.status) }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleDetail(scope.row)">查看</el-button>
            <!-- <el-button v-if="scope.row.status===4" type="text" size="mini" @click="handleAssign(scope.row)">分发</el-button> -->
            <!--<el-button type="text" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="mini" @click="handleDelete(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="spaceline" />
      <el-row :gutter="20">
        <el-col :span="16" :offset="8" style="text-align: right">
          <el-pagination
            background
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
    </el-card>

    <DetailDialog
      ref="detailDialog"
      :visible="detailDialogVisible"
      @onCancel="onCancel"
      @onHistory="onHistory"
    />
    <!-- <AssignDialog
      ref="assignDialog"
      :visible="assignDialogVisible"
      @onCancel="onCancel"
      @onConfirm="onConfirm"
    /> -->
  </div>
</template>

<script>
import { deepClone } from '@/utils/index.js'
import { collegeProcessedList } from '@/api/recruitment-manage'
import { mapGetters } from 'vuex'
import DetailDialog from '../components/DetailDialog' // 导入简历详情/历史轨迹组件
// import AssignDialog from '../components/AssignDialog' // 导入简历分发组件

export default {
  components: {
    DetailDialog,
    // AssignDialog
  },
  data() {
    return {
      searchForm: {
      },
      tableList: [],
      detailDialogVisible: false,
      // assignDialogVisible: false,
      table_loading: true,
      // 分页
      total: 0,
      currentPage: 1,
      pagesize: 10
    }
  },
  computed: {
    getStatusList() {
      return () => {
        const statuslist = deepClone(this.statuslist);
        console.log("statuslist", statuslist)
        statuslist.splice(0, 4)
        return statuslist
      }
    },
    resumeState() {
      return (value) => {
        const result = this.statuslist.find(item => item.status === value);
        return result ? result.name : '-'
      }
    },
    sourceName() {
      return (value) => {
        const result = this.sourcelist.find(item => item.id === value);
        return result ? result.name : '-';
      }
    },
    getStyle() { // 根据不同状态获取不同button样式
      return (status) => {
        switch (status) {
          case 1:
            return { color: '#409EFF' };
          case 2:
            return { color: '#005BEA' };
          case 3:
            return { color: '#5985B8' };
          case 4:
            return { color: '#ABB859', background: '#F9FFD4' };
          case 5:
            return { color: '#72B859', background: '#EDFFE7' };
          case 6:
            return { color: '#59B862', background: '#E5FFE8' };
          case 7:
            return { color: '#74C39D', background: '#EDFAF4' };
          case 8:
            return { color: '#E1C115', background: '#FFFCDD' };
          case 9:
            return { color: '#E6A23C', background: '#FFF4E2' };
          default:
            break;
        }
      }
    },
    ...mapGetters(['sourcelist', 'statuslist']), // 简历来源列表，简历状态列表
  },
  created() {
    const status = this.$route.query.status; // 从首页统计跳转过来的
    this.initPage(status)
  },
  methods: {
    search() {
      this.currentPage = 1;
      this.getTableList()
    },
    goto(target) {
      this.$router.push(target)
    },
    // 请求列表数据
    getTableList(status) {
      this.table_loading = true
      const param = {
        page: this.currentPage,
        size: this.pagesize,
        ...this.searchForm,
        status: status || this.searchForm.status
      }
      console.log(param)
      collegeProcessedList(param)
        .then(res => {
          this.tableList = res.httpCode === 200 ? res.data.list : []
          this.table_loading = false
          this.total = res.httpCode === 200 ? res.data.meta.total : []
        })
        .catch(() => {
          this.$message.error('请求列表发生错误')
          this.table_loading = false
        })
    },
    handleSizeChange(val) {
      this.pagesize = val
      this.getTableList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableList()
    },
    // 查看简历
    handleDetail(row) {
      this.$refs.detailDialog.getResumeDetail(row.id)
      this.detailDialogVisible = true
    },
    // 分发简历,学院管理员初审未通过的可以分发
    // handleAssign(row) {
    //   this.$refs.assignDialog.getRowDetail(row)
    //   this.assignDialogVisible = true
    // },
    // 初始化需要调用的接口
    initPage(status) {
      this.getTableList(status);
    },

    onCancel() {
      this.detailDialogVisible = false
      // this.assignDialogVisible = false
    },
    onConfirm() {
      this.detailDialogVisible = false
      // this.assignDialogVisible = false
    },
    onRefresh() {
      this.getTableList()
    },
    onHistory() {
      this.detailDialogVisible = false
      // this.assignDialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  .box-card{
    margin-top: 10px;
  }
  .el-form{
    .el-form-item--mini.el-form-item{
      margin-bottom:0
    }
  }
}
</style>
