var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "start" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-plus",
                    size: "mini",
                    type: "primary",
                  },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("发布海报")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.table_loading,
                  expression: "table_loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableList, "tooltip-effect": "dark" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "序号", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "海报名称", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          { attrs: { placement: "right", trigger: "hover" } },
                          [
                            _c("img", {
                              staticStyle: {
                                "max-width": "500px",
                                "max-height": "400px",
                              },
                              attrs: { src: scope.row.url },
                            }),
                            _c(
                              "span",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _vm._v(" " + _vm._s(scope.row.title) + " "),
                                _c("i", { staticClass: "el-icon-picture" }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "upload_time", label: "上传时间" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  label: "排序",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "是否显示", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(scope.row.status === 1 ? "显示" : "不显示")
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "spaceline" }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { span: 16, offset: 8 },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.pagesize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialog_loading,
              expression: "dialog_loading",
            },
          ],
          attrs: {
            title: _vm.dialogType === "edit" ? "编辑海报" : "发布海报",
            visible: _vm.dialogTableVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                rules: _vm.rules,
                "inline-message": true,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "海报名称", prop: "title" } },
                [
                  _c("el-input", {
                    staticClass: "small",
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上传", prop: "url" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "thumb-uploader",
                      attrs: {
                        action: _vm.apiAttachUpload,
                        headers: _vm.headers,
                        "show-file-list": false,
                        "on-success": _vm.handleThumbSuccess,
                        "before-upload": _vm.beforeThumbUpload,
                      },
                    },
                    [
                      _vm.form.url
                        ? _c("img", {
                            staticClass: "thumb",
                            attrs: { src: _vm.form.url },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus thumb-uploader-icon",
                          }),
                    ]
                  ),
                  _c("span", { staticClass: "smalltip" }, [
                    _c("i", { staticClass: "el-icon-info" }),
                    _vm._v(" 建议尺寸612*830 "),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input", {
                    staticClass: "mini",
                    attrs: { maxlength: "14" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", _vm._n($$v))
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否显示", prop: "status" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    on: {
                      change: function ($event) {
                        _vm.form.status === 0 ? 1 : 0
                      },
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSave("form")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }