
<!--简历详情/历史记录组件-->
<template>
  <div>
    <el-dialog title="简历详情" :visible.sync="visible" :before-close="onCancel" :close-on-click-modal="false">
      <el-form size="small">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          {{ detail.user_name }}
        </el-form-item>
        <el-form-item label="手机号" :label-width="formLabelWidth">
          {{ detail.phone_number }}
        </el-form-item>
        <el-form-item label="邮箱" :label-width="formLabelWidth">
          {{ detail.email }}
        </el-form-item>
        <el-form-item label="意向学院" :label-width="formLabelWidth">
          {{ collegeName(detail.college_id) }}
        </el-form-item>
        <el-form-item label="简历" :label-width="formLabelWidth">
          <el-link
            v-for="att, index in detail.att_content"
            :key="index"
            :href="att.url"
            type="primary"
            :underline="false"
            target="_blank"
          >
            <img :src="icon_file"> {{ att.filename }}
            <img style="margin-left:20px" :src="icon_down">
          </el-link>
        </el-form-item>
        <el-form-item label="简历来源" :label-width="formLabelWidth">
          {{ sourceName(detail.source) }}
        </el-form-item>
        <el-form-item label="简历状态" :label-width="formLabelWidth">
          {{ resumeStatus(detail.status) }}
        </el-form-item>
        <!-- <el-form-item v-if="detail.form_file && detail.form_file.length>0" label="详细表格" :label-width="formLabelWidth">
          <el-link
            v-for="att, index in detail.form_file"
            :key="index"
            :href="att.url"
            type="primary"
            :underline="false"
            target="_blank"
          >
            <img :src="icon_file"> {{ att.filename }}
            <img style="margin-left:20px" :src="icon_down">
          </el-link>
        </el-form-item> -->
        <template v-if="detail.data_detail">
          <el-form-item v-if="detail.status >= 5" label="应聘表" :label-width="formLabelWidth">
            <el-link
              type="primary"
              :underline="false"
              @click="renderDoc"
            >
              <img :src="icon_file">
              <span v-if="detail.data_detail.type === 0">华南师范大学教师岗位应聘表 - {{ detail.data_detail.name }}</span>
              <span v-else>华南师范大学青年人才选聘计划书 - {{ detail.data_detail.name }}</span>
              <img style="margin-left:20px" :src="icon_down">
            </el-link>
          </el-form-item>
          <el-form-item v-if="detail.status >= 6" label="证明材料及其他附件" :label-width="formLabelWidth">
            <el-link
              v-for="att, index in evidenceAttachs"
              :key="index"
              :href="att.url"
              type="primary"
              :underline="false"
              target="_blank"
            >
              <img :src="icon_file"> {{ att.filename }}
              <img style="margin-left:20px" :src="icon_down">
            </el-link>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="onCancel">取 消</el-button>
        <!-- 超级管理员 -->
        <el-button v-if="roles[0].id === 1" size="mini" type="primary" @click="onHistory">历史轨迹</el-button>
      </div>
    </el-dialog>
    <el-dialog title="历史轨迹" :visible.sync="historyVisible" :close-on-click-modal="false">
      <el-table :data="detail.status_log" size="mini">
        <el-table-column property="phone_number" label="姓名">
          {{ detail.user_name }}
        </el-table-column>
        <el-table-column property="updated_at" label="时间" />
        <el-table-column property="last_status" label="变更前">
          <template slot-scope="scope">
            {{ resumeStatus(scope.row.last_status) }}
          </template>
        </el-table-column>
        <el-table-column property="current_status" label="变更后">
          <template slot-scope="scope">
            {{ resumeStatus(scope.row.current_status) }}
          </template>
        </el-table-column>
        <el-table-column property="admin_name" label="处理人" />
        <el-table-column property="refuse_reason" label="不通过原因" />
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="historyVisible=false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { resumeDetail } from "@/api/recruitment-manage";
import icon_file from "@/assets/images/icon_file.svg";
import icon_down from "@/assets/images/icon_down.svg";
import { mapGetters } from 'vuex'
// 生成文档相关
import Docxtemplater from "docxtemplater";
import ImageModuel from "docxtemplater-image-module-free";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { UrlToBase64, base64DataURLToArrayBuffer } from "@/utils/index";

export default {
  name: 'DetailDialog', // 导入简历组件
  props: {
    visible: {
      // 是否显示
      type: Boolean,
      required: true,
      default: function() {
        return true
      },
    }
  },
  data() {
    return {
      icon_file,
      icon_down,
      detail: {}, // 历史记录在详情里面
      formLabelWidth: '90px',
      historyVisible: false, // 历史记录弹框
      evidenceAttachs: [], // 证明材料及其他附件
    }
  },
  computed: {
    ...mapGetters(['roles', 'collegelist', 'sourcelist', 'statuslist'])
  },
  mounted() {
  },
  created() {

  },
  methods: {
    collegeName(id) {
      const result = this.collegelist.find(item => item.id === id)
      return result ? result.name : '-';
    },
    sourceName(source) {
      const result = this.sourcelist.find(item => item.id === source);
      return result ? result.name : '-';
    },
    resumeStatus(status) {
      const result = this.statuslist.find(item => item.status === status);
      return result ? result.name : '-';
    },
    onCancel(row) {
      this.$emit('onCancel')
    },
    onHistory() {
      this.$emit('onHistory');
      this.historyVisible = true;
    },
    getResumeDetail(id) {
      resumeDetail({ id }).then(res => {
        this.detail = res.httpCode === 200 ? res.data : [];
        if (this.detail.status >= 6 && this.detail.data_detail) {
          this.evidenceAttachs = [
            ...this.detail.data_detail.paper_situation.last_five_year_published_info.prove,
            ...this.detail.data_detail.last_five_year_patent.prove,
            ...this.detail.data_detail.research_projects.prove,
            ...this.detail.data_detail.last_five_year_rewards.prove,
            ...this.detail.data_detail.att_content,
          ];
        }
      }).catch(() => {})
    },
    loadFile(url, callback) {
      PizZipUtils.getBinaryContent(url, callback);
    },
    // 生成文档
    renderDoc() {
      if (!this.detail.data_detail) {
        this.$message.error('该简历没有填写应聘表');
        return;
      }
      const positionList = ['', '教授', '副教授', '讲师', '研究员', '副研究员', '助理研究员'];
      const booleanText = ['', '是', '否'];
      const genderText = ['', '男', '女'];
      const marriageText = ['', '未婚', '已婚', '离异', '丧偶'];
      const healthText = ['', '健康', '良好', '一般', '较差'];
      const { learning_experience, work_experience, paper_situation, last_five_year_paper, other_paper, last_five_year_patent, research_projects, last_five_year_rewards } = this.detail.data_detail;
      const docData = {
        applicant_name: this.detail.data_detail.applicant_name || '无',
        apply_position_id: positionList[this.detail.data_detail.apply_position_id] || '无',
        applicant_college_id: this.collegelist.find(item => item.id === this.detail.data_detail.applicant_college_id).name || '无',
        now_work_enterprise: this.detail.data_detail.now_work_enterprise || '无',
        now_position: this.detail.data_detail.now_position || '无',
        discipline: this.detail.data_detail.discipline || '无',
        updated_at: this.detail.data_detail.updated_at.substring(0, 10).replaceAll('-', '.'),
        name: this.detail.data_detail.name || '无',
        gender: genderText[this.detail.data_detail.gender] || '无',
        birthday: this.detail.data_detail.birthday.replace('-', '.') || '无',
        nationality: this.detail.data_detail.nationality || '无',
        native_place: this.detail.data_detail.native_place || '无',
        nation: this.detail.data_detail.nation || '无',
        political_outlook: this.detail.data_detail.political_outlook || '无',
        religion: this.detail.data_detail.religion || '无',
        belong_discipline: this.detail.data_detail.belong_discipline || '无',
        research_direction: this.detail.data_detail.research_direction || '无',
        position_title: this.detail.data_detail.position_title || '无',
        height: this.detail.data_detail.height || '无',
        health_condition: healthText[this.detail.data_detail.health_condition] || '无',
        is_physical_disability: booleanText[this.detail.data_detail.is_physical_disability] || '无',
        address: this.detail.data_detail.address || '无',
        id_number: this.detail.data_detail.id_number || '无',
        email: this.detail.data_detail.email || '无',
        phone_number: this.detail.data_detail.phone_number || '无',
        marital_status: marriageText[this.detail.data_detail.marital_status] || '无',
        spouse_name: this.detail.data_detail.spouse_name || '无',
        spouse_education: this.detail.data_detail.spouse_education || '无',
        spouse_work_enterprise: this.detail.data_detail.spouse_work_enterprise || '无',
        has_our_school_relation: booleanText[this.detail.data_detail.has_our_school_relation] || '无',
        relation_people_name: this.detail.data_detail.relation_people_name || '无',
        relation_work_enterprise: this.detail.data_detail.relation_work_enterprise || '无',
        main_academic_positions: this.detail.data_detail.main_academic_positions || '无',
        teaching_or_research_situation: this.detail.data_detail.teaching_or_research_situation || '无',
        patent_num: this.detail.data_detail.last_five_year_patent.num || '0',
        patent_invent_num: this.detail.data_detail.last_five_year_patent.get_num || '0',
        applicant_discipline: this.detail.data_detail.applicant_discipline || '无',
        applicant_position: positionList[this.detail.data_detail.applicant_position] || '无',
        work_plan: this.detail.data_detail.work_plan || '无',
        checkbox_1: this.detail.data_detail.applicant_position === 1,
        checkbox_2: this.detail.data_detail.applicant_position === 2,
        checkbox_3: this.detail.data_detail.applicant_position === 3,
        checkbox_4: this.detail.data_detail.applicant_position === 4,
        checkbox_5: this.detail.data_detail.applicant_position === 5,
        checkbox_6: this.detail.data_detail.applicant_position === 6,
      };
      // 学习经历
      learning_experience.forEach((item, index) => {
        docData[`learning_experience_time_${index}`] = item.time_area.replaceAll('-', '.').replace(',', '-') || '无';
        docData[`learning_experience_school_${index}`] = item.school || '无';
        docData[`learning_experience_speciality_${index}`] = item.speciality || '无';
      });
      // 工作经历
      if (work_experience && work_experience.length > 0) {
        work_experience.forEach((item, index) => {
          docData[`work_experience_time_${index}`] = item.time_area.replaceAll('-', '.').replace(',', '-') || '';
          docData[`work_experience_work_enterprise_${index}`] = item.enterprise || '';
          docData[`work_experience_position_${index}`] = item.position || '';
          docData[`work_experience_remark_${index}`] = item.remark || '';
        });
      }
      // 论文情况
      Object.keys(paper_situation.published_info).forEach(key => {
        Object.keys(paper_situation.published_info[key]).forEach(item => {
          docData[`paper_situation_${key}_${item}`] = paper_situation.published_info[key][item] || '0';
        });
      });
      Object.keys(paper_situation.last_five_year_published_info).forEach(key => {
        Object.keys(paper_situation.last_five_year_published_info[key]).forEach(item => {
          docData[`paper_situation_last_five_year_${key}_${item}`] = paper_situation.last_five_year_published_info[key][item] || '0';
        });
      });
      // 近五年发表论文
      last_five_year_paper.forEach((item, index) => {
        docData[`last_five_year_paper_title_${index}`] = item.title || '';
        docData[`last_five_year_paper_issn_${index}`] = item.issn || '';
        docData[`last_five_year_paper_stage_${index}`] = item.stage || '';
        docData[`last_five_year_paper_search_type_${index}`] = item.search_type || '';
        docData[`last_five_year_paper_impact_factors_${index}`] = item.impact_factors || '';
        docData[`last_five_year_paper_argument_${index}`] = item.argument || '';
        docData[`last_five_year_paper_search_number_${index}`] = item.search_number || '';
      });
      // 其他论文
      other_paper.forEach((item, index) => {
        docData[`other_paper_title_${index}`] = item.title || '';
        docData[`other_paper_issn_${index}`] = item.issn || '';
        docData[`other_paper_stage_${index}`] = item.stage || '';
        docData[`other_paper_search_type_${index}`] = item.search_type || '';
        docData[`other_paper_impact_factors_${index}`] = item.impact_factors || '';
        docData[`other_paper_argument_${index}`] = item.argument || '';
        docData[`other_paper_search_number_${index}`] = item.search_number || '';
      });
      // 近五年专利
      last_five_year_patent.content.forEach((item, index) => {
        docData[`last_five_year_patent_name_${index}`] = item.name || '';
        docData[`last_five_year_patent_county_${index}`] = item.county || '';
        docData[`last_five_year_patent_patent_number_${index}`] = item.patent_number || '';
        docData[`last_five_year_patent_rank_${index}`] = item.rank || '';
        docData[`last_five_year_patent_time_${index}`] = item.time || '';
      });
      // 科研项目
      research_projects.content.forEach((item, index) => {
        docData[`research_projects_title_${index}`] = item.title || '';
        docData[`research_projects_source_${index}`] = item.source || '';
        docData[`research_projects_time_area_${index}`] = item.time_area || '';
        docData[`research_projects_funds_${index}`] = item.funds || '';
        docData[`research_projects_rank_and_task_${index}`] = item.rank_and_task || '';
      });
      // 近五年获奖情况
      last_five_year_rewards.content.forEach((item, index) => {
        docData[`last_five_year_rewards_title_${index}`] = item.title || '';
        docData[`last_five_year_rewards_enterprise_${index}`] = item.enterprise || '';
        docData[`last_five_year_rewards_level_${index}`] = item.level || '';
        docData[`last_five_year_rewards_time_${index}`] = item.time || '';
        docData[`last_five_year_rewards_rank_${index}`] = item.rank || '';
        docData[`last_five_year_rewards_award_company_${index}`] = item.award_company || '';
      });
      if (this.detail.data_detail.photo) {
        UrlToBase64(this.detail.data_detail.photo, (base64) => {
          docData.photo = base64;
        });
      }

      // const loadUrl = [
      //   "https://upload.weiqingxuezhe.com/static/njust/documents/Teacher.docx",
      //   "https://upload.weiqingxuezhe.com/static/njust/documents/YoungTalent.docx",
      // ];
      const loadUrl = [`./documents/Teacher.docx`, `./documents/YoungTalent.docx`];
      this.loadFile(loadUrl[this.detail.data_detail.type], (error, content) => {
        if (error) {
          throw error;
        }
        const imageOpts = {
          getImage() {
            return base64DataURLToArrayBuffer(docData.photo);
          },
          getSize() {
            return [80, 112];
          }
        };
        const zip = new PizZip(content);
        const doc = new Docxtemplater().loadZip(zip).attachModule(new ImageModuel(imageOpts));
        doc.setData(docData);
        try {
          // render the document
          doc.render();
        } catch (error) {
          console.log(JSON.stringify({ error: error }));

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function(error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
          }
          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        // Output the document using Data-URI
        const filenames = [
          '华南师范大学教师岗位应聘表',
          '华南师范大学青年拔尖人才选聘计划申报书',
        ];
        saveAs(out, `${filenames[this.detail.data_detail.type]} - ${this.detail.data_detail.name}`);
      });
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep {
  .el-form-item__label {
    line-height: 1.2;
    padding-top: 8px;
  }
  .el-form-item__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .el-link--inner {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
</style>

