<template>
  <div class="app-container">
    <el-card class="box-card">
      <el-row type="flex" justify="start">
        <el-button icon="el-icon-plus" size="mini" type="primary" @click="handleCreate">发布海报</el-button>
      </el-row>
      <el-table
        v-loading="table_loading"
        :data="tableList"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="序号"
          width="120"
        >
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          label="海报名称"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-popover placement="right" trigger="hover">
              <img
                :src="scope.row.url"
                style="max-width: 500px; max-height: 400px"
              >
              <span slot="reference">
                {{ scope.row.title }}
                <i class="el-icon-picture" />
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="upload_time"
          label="上传时间"
        />
        <el-table-column
          prop="sort"
          label="排序"
          show-overflow-tooltip
        />

        <el-table-column
          label="是否显示"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.status === 1 ? '显示' : '不显示' }}</template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="200"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="spaceline" />
      <el-row :gutter="20">
        <el-col :span="16" :offset="8" style="text-align: right">
          <el-pagination
            background
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
    </el-card>

    <!-- 编辑海报/添加海报对话框 -->
    <el-dialog v-loading="dialog_loading" :title="dialogType === 'edit' ? '编辑海报' : '发布海报'" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
      <el-form
        ref="form"
        class="common-form"
        :model="form"
        label-width="100px"
        :rules="rules"
        :inline-message="true"
      >
        <el-form-item label="海报名称" prop="title">
          <el-input v-model="form.title" class="small" />
        </el-form-item>
        <el-form-item label="上传" prop="url">
          <el-upload
            class="thumb-uploader"
            :action="apiAttachUpload"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleThumbSuccess"
            :before-upload="beforeThumbUpload"
          >
            <img v-if="form.url" :src="form.url" class="thumb">
            <i v-else class="el-icon-plus thumb-uploader-icon" />
          </el-upload>
          <span class="smalltip"><i class="el-icon-info" /> 建议尺寸612*830 </span>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model.number="form.sort" class="mini" maxlength="14" />
        </el-form-item>
        <el-form-item label="是否显示" prop="status">
          <el-switch
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            @change="form.status === 0 ? 1 : 0"
          />
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="handleSave('form')">保存</el-button>
          <el-button @click="dialogTableVisible=false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getPosterList, delPoster, addPoster, editPoster } from '@/api/content-manage'
import { apiAttachUpload, configFile } from '@/utils/constant'
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      apiAttachUpload: apiAttachUpload, // 通用上传接口,
      configFile: configFile, // 文件上传配置，比如格式，大小
      headers: { Authorization: getToken() },
      form: {
        title: '',
        sort: 0,
        url: '', // 海报url
        status: 1 // 1，显示，0 未显示
      },
      tableList: [],
      dialogTableVisible: false,
      dialogType: '',
      table_loading: false,
      dialog_loading: false,
      // 分页
      total: 0,
      currentPage: 1,
      pagesize: 10,
      rules: {
        title: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ],
        sort: [
          { type: 'number', message: '排序必须为数字', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请上传海报', trigger: 'blur' }
        ]
      },
    }
  },
  created() {
    this.initPage()
  },
  methods: {
    handleCreate() {
      this.dialogType = 'add';
      this.dialogTableVisible = true;
      this.form = {
        title: '',
        sort: 0,
        url: '',
        status: 1
      }
    },
    handleEdit(row) {
      this.dialogType = 'edit';
      this.dialogTableVisible = true;
      this.form.id = row.id;
      this.form.title = row.title;
      this.form.sort = row.sort
      this.form.url = row.url
      this.form.status = row.status
    },
    handleSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = this.form
          if (this.dialogType === 'edit') {
            editPoster(params).then(res => {
              if (res.httpCode === 200 && res.statusCode === 0) {
                this.$message({
                  message: '海报修改成功',
                  type: 'success'
                });
                this.initPage();
                this.dialogTableVisible = false
              } else {
                this.$message.error(res.message)
              }
            }).catch(() => {
              this.$message.error('修改海报发生错误')
            })
          } else {
            this.dialog_loading = true
            addPoster(params).then(res => {
              if (res.httpCode === 200 && res.statusCode === 0) {
                this.$message({
                  message: '海报添加成功',
                  type: 'success'
                });
                this.dialog_loading = false
                this.initPage()
                this.dialogTableVisible = false
              } else {
                this.$message.error(res.message)
                this.dialog_loading = false
              }
            }).catch(() => {
              this.$message.error('添加海报发生错误')
              this.dialog_loading = false
            })
          }
        } else {
          return false
        }
      })
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPoster({ id: [row.id] })
          .then(res => {
            if (res.httpCode === 200 && res.statusCode === 0) {
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.getTableList()
            }
          })
          .catch(() => {
            this.$message.error('删除发生错误')
          })
      }).catch(() => {})
    },
    handleThumbSuccess(res, file) {
      if (res.httpCode === 200 && res.statusCode === 0) {
        this.form.url = res.data.url
      } else {
        this.$message.error(res.message)
        return false
      }
    },
    beforeThumbUpload(file) {
      const filetypeArr = file.type.split('/')
      const filetype = filetypeArr[1].toLowerCase()
      const configFileExtArr = configFile.fileupload_ext.split(',')

      if (!configFileExtArr.includes(filetype)) {
        this.$message.error('上传文件格式不允许')
        return false
      }
      if (file.size / 1024 > configFile.fileupload_size) {
        this.$message.error(`上传文件最大为${configFile.fileupload_size}kb`)
        return false
      }
      return true
    },
    goto(target) {
      this.$router.push(target)
    },
    // 请求列表数据
    getTableList() {
      this.table_loading = true

      const param = {
        page: this.currentPage,
        size: this.pagesize,
      }
      getPosterList(param)
        .then(res => {
          this.tableList = res.httpCode === 200 ? res.data.list : []
          this.table_loading = false
          this.total = res.httpCode === 200 ? res.data.meta.total : []
        })
        .catch(() => {
          this.$message.error('请求列表发生错误')
          this.table_loading = false
        })
    },
    handleSizeChange(val) {
      this.pagesize = val
      this.getTableList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableList()
    },
    // 初始化需要调用的接口
    initPage() {
      this.getTableList();
    },
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  .box-card{
    margin-top: 10px;
  }
  .thumb-uploader {
  display: inline-block;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 122px;
  height: 122px;
  }
  .thumb-uploader img {
    width: 120px;
    height: 120px;
    border-radius: 6px;
  }
  .thumb-uploader:hover {
    border-color: #409eff;
  }
  .thumb-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
  .smalltip{
    margin-left: 10px;
    position: absolute;
    bottom: 0;
  }
}
</style>
