import request from '@/utils/request'
/**
 *
 * 角色列表
 *
 */
export function getRoles(params) {
  return request({
    url: '/admin/role',
    method: 'get',
    params
  })
}

/**
 *
 * 管理员管理
 *
 */
export function getCaptcha() {
  return request({
    url: '/admin/captcha',
    method: 'post'
  })
}

export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data
  })
}

export function getPermission() {
  return request({
    url: '/admin/admin/permissionList',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/admin/logout',
    method: 'post'
  })
}

export function getUserList(params) {
  return request({
    url: '/admin/admin',
    method: 'get',
    params
  })
}
// 添加管理员用户
export function addUser(data) {
  return request({
    url: '/admin/admin',
    method: 'post',
    data
  })
}
// 获取管理员详情
export function getUserDetail(id) {
  return request({
    url: `/admin/admin/${id}`,
    method: 'get'
  })
}
// 编辑管理员
export function updateUser(id, data) {
  return request({
    url: `/admin/admin/${id}`,
    method: 'put',
    data
  })
}
// 删除管理员
export function deleteUser(id) {
  return request({
    url: `/admin/admin/${id}`,
    method: 'delete'
  })
}
// 获取学院列表
export function getCollegeList() {
  return request({
    url: `/admin/college/list`,
    method: 'get'
  })
}
// 获取职位列表
export function getPositionList() {
  return request({
    url: `/admin/position`,
    method: 'get'
  })
}

// 首页统计数据
export function indexStatistic() {
  return request({
    url: `/admin/resume/statistic`,
    method: 'get'
  })
}
