var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container login-background" },
    [
      _c("logo"),
      _c("div", { staticClass: "login-form" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "img" }, [
            _c("img", { attrs: { src: _vm.login_bg } }),
          ]),
          _c("div", { staticClass: "welcome" }, [
            _c("img", { attrs: { src: _vm.login_welcome } }),
          ]),
          _c("div", { staticClass: "name" }, [
            _vm._v("华南师范大学招聘后台管理系统~"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-form",
              {
                ref: "loginForm",
                attrs: {
                  model: _vm.loginForm,
                  rules: _vm.loginRules,
                  autocomplete: "on",
                  "label-position": "top",
                },
              },
              [
                _c("div", { staticClass: "title-container" }, [
                  _c("h3", { staticClass: "title" }, [_vm._v("请登录")]),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { prop: "username", label: "账号", size: "small" } },
                  [
                    _c("el-input", {
                      ref: "username",
                      attrs: {
                        placeholder: "请输入账号",
                        name: "username",
                        type: "text",
                        tabindex: "1",
                        autocomplete: "on",
                      },
                      model: {
                        value: _vm.loginForm.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "username", $$v)
                        },
                        expression: "loginForm.username",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "Caps lock is On",
                      placement: "right",
                      manual: "",
                    },
                    model: {
                      value: _vm.capsTooltip,
                      callback: function ($$v) {
                        _vm.capsTooltip = $$v
                      },
                      expression: "capsTooltip",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "password",
                          label: "密码",
                          size: "small",
                        },
                      },
                      [
                        _c("el-input", {
                          key: _vm.passwordType,
                          ref: "password",
                          attrs: {
                            type: _vm.passwordType,
                            placeholder: "请输入不少于6位密码",
                            name: "password",
                            tabindex: "2",
                            autocomplete: "on",
                          },
                          on: {
                            blur: function ($event) {
                              _vm.capsTooltip = false
                            },
                          },
                          nativeOn: {
                            keyup: [
                              function ($event) {
                                return _vm.checkCapslock($event)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleLogin($event)
                              },
                            ],
                          },
                          model: {
                            value: _vm.loginForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "password", $$v)
                            },
                            expression: "loginForm.password",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "show-pwd",
                            on: { click: _vm.showPwd },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class":
                                  _vm.passwordType === "password"
                                    ? "eye"
                                    : "eye-open",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "188px" },
                    attrs: { prop: "captcha", label: "验证码", size: "small" },
                  },
                  [
                    _c("el-input", {
                      ref: "captcha",
                      attrs: {
                        placeholder: "请输入验证码",
                        name: "captcha",
                        type: "text",
                        tabindex: "1",
                        autocomplete: "on",
                        maxlength: "4",
                        minlength: "4",
                      },
                      model: {
                        value: _vm.loginForm.captcha,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "captcha", $$v)
                        },
                        expression: "loginForm.captcha",
                      },
                    }),
                    _c("el-image", {
                      staticClass: "captcha",
                      attrs: { src: _vm.captcha_img },
                      on: {
                        click: function ($event) {
                          return _vm.getCaptcha()
                        },
                      },
                    }),
                    _c("el-input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false",
                        },
                      ],
                      model: {
                        value: _vm.loginForm.captcha_key,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "captcha_key", $$v)
                        },
                        expression: "loginForm.captcha_key",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "login-bt",
                    staticStyle: { width: "100%", "margin-bottom": "30px" },
                    attrs: { loading: _vm.loading, type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleLogin($event)
                      },
                    },
                  },
                  [_vm._v("登录")]
                ),
                _c("div", { staticStyle: { position: "relative" } }, [
                  _c("div", { staticClass: "tips" }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }