var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "填写原因",
        visible: _vm.visible,
        "before-close": _vm.onCancel,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, size: "small" } },
        [
          _c(
            "el-form-item",
            { attrs: { required: true } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入原因",
                  autosize: { minRows: 4, maxRows: 8 },
                  type: "textarea",
                },
                model: {
                  value: _vm.form.refuse_reason,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "refuse_reason", $$v)
                  },
                  expression: "form.refuse_reason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.onCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onConfirm("form")
                },
              },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }