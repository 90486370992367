var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_c("span", [_vm._v("分类管理")])]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.categories,
                "row-key": "id",
                border: "",
                "default-expand-all": "",
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "分类", sortable: "" },
              }),
              _c("el-table-column", {
                attrs: { label: "分类等级" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.level === 0
                          ? _c("el-tag", [_vm._v("一级分类")])
                          : scope.row.level === 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("二级分类"),
                            ])
                          : _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("三级分类"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(_vm._s(_vm.getTypeNameById(scope.row.type))),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "model", label: "模型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(_vm.getModelNameById(scope.row.template_id))
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", { attrs: { prop: "sort", label: "排序" } }),
              _c("el-table-column", {
                attrs: { prop: "is_show", label: "导航显示" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.is_show === 1 ? "显示" : "不显示"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "相关操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.funEdit(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.funDelete(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "spaceline" }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.funAdd()
                        },
                      },
                    },
                    [_vm._v(" 添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogType === "edit" ? "编辑分类" : "添加分类",
            visible: _vm.dialogTableVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                model: _vm.form,
                "label-width": "100px",
                rules: _vm.rules,
                "inline-message": true,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("频道")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("列表")]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("跳转")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.type !== 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "模型", prop: "template_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.template_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "template_id", $$v)
                            },
                            expression: "form.template_id",
                          },
                        },
                        _vm._l(_vm.modelList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "所属分类", prop: "pid" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      disabled: _vm.dialogType === "edit",
                      placeholder: "顶级",
                      options: _vm.categories,
                      "show-all-levels": false,
                      props: {
                        checkStrictly: true,
                        label: "name",
                        value: "id",
                        emitPath: false,
                      },
                      "popper-class": "cancelRadio",
                      clearable: "",
                    },
                    on: { change: _vm.changecategory },
                    model: {
                      value: _vm.form.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pid", $$v)
                      },
                      expression: "form.pid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "small",
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input", {
                    staticClass: "mini",
                    attrs: { maxlength: "14" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", _vm._n($$v))
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _vm.form.type === 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "跳转URL" } },
                    [
                      _c("el-input", {
                        staticClass: "mini",
                        attrs: { placeholder: "类型为跳转链接时填写" },
                        model: {
                          value: _vm.form.link,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "link", $$v)
                          },
                          expression: "form.link",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "导航显示" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.form.is_show,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_show", $$v)
                      },
                      expression: "form.is_show",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.funSave("form")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }