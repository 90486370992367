import request from '@/utils/request'
/**
 *
 * 招聘管理
 *
 */
// 超级管理员简历信息列表
export function getResumeList(params) {
  return request({
    url: '/admin/resume/list',
    method: 'get',
    params
  })
}
// 简历状态列表
export function getStatusList() {
  return request({
    url: '/admin/resume/status/list',
    method: 'get'
  })
}
// 后台导入简历
export function importResume(data) {
  return request({
    url: '/admin/resume/import',
    method: 'post',
    data
  })
}
// 简历详情
export function resumeDetail(params) {
  return request({
    url: '/admin/resume/detail',
    method: 'get',
    params
  })
}
// 学院管理员列表
export function collegeAdminList() {
  return request({
    url: '/admin/college/admin/list',
    method: 'get'
  })
}
// 超级管理员分发
export function adminAssign(data) {
  return request({
    url: '/admin/resume/allocate',
    method: 'post',
    data
  })
}
// 编辑简历
export function editResume(data) {
  return request({
    url: '/admin/resume/edit',
    method: 'post',
    data
  })
}
// 删除/加入回收站简历
export function addRecycle(data) {
  return request({
    url: '/admin/resume/recycle/add',
    method: 'post',
    data
  })
}
// 从回收站还原
export function revertRecycle(data) {
  return request({
    url: '/admin/resume/recycle/reduction',
    method: 'post',
    data
  })
}
// 回收站简历列表
export function recycleList(params) {
  return request({
    url: '/admin/resume/recycle/list',
    method: 'get',
    params
  })
}
// 学院管理员简历列表
export function collegeResumeList(params) {
  return request({
    url: '/admin/resume/college/list',
    method: 'get',
    params
  })
}
// 学院管理员已处理简历列表
export function collegeProcessedList(params) {
  return request({
    url: '/admin/resume/college/processed/list',
    method: 'get',
    params
  })
}
// 超级管理员审批：录用或不录用
export function adminReview(data) {
  return request({
    url: '/admin/resume/admin/review',
    method: 'post',
    data
  })
}
// 学院管理员简历初审：通过或不通过
export function initalReview(data) {
  return request({
    url: '/admin/resume/examine',
    method: 'post',
    data
  })
}
// 学院管理员审批：通过或不通过
export function collegeReview(data) {
  return request({
    url: '/admin/resume/college/review',
    method: 'post',
    data
  })
}
