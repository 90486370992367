<template>
  <div class="login-container login-background">
    <logo />
    <!-- <vue-particles color="#dedede" /> -->
    <div class="login-form">
      <div class="left">
        <div class="img">
          <img :src="login_bg">
        </div>
        <div class="welcome"><img :src="login_welcome"></div>
        <div class="name">华南师范大学招聘后台管理系统~</div>
      </div>
      <div class="right">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" autocomplete="on" label-position="top">
          <div class="title-container">
            <h3 class="title">请登录</h3>
          </div>
          <el-form-item prop="username" label="账号" size="small">
            <el-input
              ref="username"
              v-model="loginForm.username"
              placeholder="请输入账号"
              name="username"
              type="text"
              tabindex="1"
              autocomplete="on"
            />
          </el-form-item>
          <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
            <el-form-item prop="password" label="密码" size="small">
              <!-- <span class="svg-container">
                <svg-icon icon-class="password" />
              </span> -->
              <el-input
                :key="passwordType"
                ref="password"
                v-model="loginForm.password"
                :type="passwordType"
                placeholder="请输入不少于6位密码"
                name="password"
                tabindex="2"
                autocomplete="on"
                @keyup.native="checkCapslock"
                @blur="capsTooltip = false"
                @keyup.enter.native="handleLogin"
              />
              <span class="show-pwd" @click="showPwd">
                <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
              </span>
            </el-form-item>
          </el-tooltip>
          <el-form-item prop="captcha" style="width: 188px;" label="验证码" size="small">
            <!-- <span class="svg-container">
              <svg-icon icon-class="lock" />
            </span> -->
            <el-input
              ref="captcha"
              v-model="loginForm.captcha"
              placeholder="请输入验证码"
              name="captcha"
              type="text"
              tabindex="1"
              autocomplete="on"
              maxlength="4"
              minlength="4"
            />
            <el-image class="captcha" :src="captcha_img" @click="getCaptcha()" />
            <el-input v-show="false" v-model="loginForm.captcha_key" />
          </el-form-item>
          <el-button class="login-bt" :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">登录</el-button>

          <div style="position:relative">
            <div class="tips" />
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { validUsername } from '@/utils/validate'
import { getCaptcha } from '@/api/system_setting'
import Logo from '@/layout/components/Sidebar/Logo'
import login_bg from '@/assets/images/login_bg.svg'
import login_welcome from '@/assets/images/login_welcome.svg'

export default {
  name: 'Login',
  components: { Logo },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error('请输入正确的用户名'))
      } else {
        callback();
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码不能少于6位'))
      } else {
        callback();
      }
    }
    return {
      login_bg,
      login_welcome,
      loginForm: {
        username: '',
        password: '',
        captcha: '',
        captcha_key: '',
      },
      captcha_img: '', // 验证码图片
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        captcha: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }, {
          min: 4,
          message: '请输入4位验证码',
          trigger: 'blur'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {}
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  created() {
    // window.addEventListener('storage', this.afterQRScan)
    // 获取验证码
    this.getCaptcha();
  },
  mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    } else if (this.loginForm.captcha === '') {
      this.$refs.captcha.focus()
    }
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 监听回车键执行事件
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.handleLogin()
      }
    },

    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/getCollegeList'); // 持久化学院列表
          this.$store.dispatch('user/getPositionList'); // 持久化职位列表
          this.$store.dispatch('user/getStatusList'); // 持久化简历状态列表
          this.$store.dispatch('user/getSourcelist'); // 持久化简历来源列表
          this.$store.dispatch('user/login', this.loginForm)
            .then(() => {
              this.$router.push({ path: '/' })
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.$message({
                message: error.message,
                type: 'warning'
              });
              this.getCaptcha()
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    // 获取验证码
    getCaptcha() {
      getCaptcha()
        .then(res => {
          this.loginForm.captcha_key = res.data.key;
          this.captcha_img = res.data.img;
        })
        .catch(() => {
          this.$message({
            message: '验证码获取异常',
            type: 'warning'
          });
        })
    },
    // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  }

}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com//vue-element-admin/pull/927 */

$bg:#283443;
$light_gray:#fff;
$cursor: #333;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}
// .login-background {//可设置背景图 铺满屏
//   background: linear-gradient(-180deg, #dceaf2 0%, #ffffff 100%);
//   width: 100%;
//   height: 100%;
//   position: absolute;
// }

/* reset element-ui css */
.login-container {
  height:100vh;
  overflow: hidden;
  // .el-input {
  //   display: inline-block;
  //   height: 47px;
  //   width: 85%;

  //   input {
  //     background: transparent;
  //     border: 0px;
  //     -webkit-appearance: none;
  //     border-radius: 0px;
  //     padding: 12px 5px 12px 15px;
  //     color: $light_gray;
  //     height: 47px;
  //     caret-color: $cursor;

  //     &:-webkit-autofill {
  //       box-shadow: 0 0 0px 1000px rgba(137, 47, 232, 0.1) inset !important;
  //       -webkit-text-fill-color: $cursor !important;
  //       transition: background-color 5000s ease-in-out 0s;
  //     }
  //   }
  // }

  .el-form-item {
    label{
      padding: 0
    }
  }
}
</style>

<style lang="scss" scoped>
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    display: flex;
    border-radius: 4px;
    background-color: #fff;
    backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 5px 25px 0px rgba(0, 3, 72, 0.1);
    position: absolute;
    width: 920px;
    height: 510px;
    max-width: 100%;
    padding: 26px 65px 0;
    box-sizing: border-box;
    margin: 0 auto;
    overflow: hidden;
    left:50%;
    margin-left: -460px;
    top:50%;
    margin-top: -250px;
    .captcha{
      position: absolute;
      right: -110px;
      width: 105px;
      height: 32px;
      top: 0;
      border: 1px dashed #ccc;
      &:hover{
        cursor:pointer
      }
    }
    .left{
      display: flex;
      flex-direction: column;
      width: 50%;
      .img{
        width: 400px;
        height: 300px;
        border: 1px dashed #ccc;
        img{

        }
      }
      .welcome{
        padding: 20px 0;
      }
      .name{
        color: #0B53D8;
        font-size: 20px;
      }
    }
    .right{
      display:flex;
      margin-left: 68px;
      form{
        margin-left: 44px;
        width: 300px;
      }
    }
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: #fff;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: #1E6DFF;
      margin: 26px auto 26px auto;
      text-align: left;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 1px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .login-bt{
    border:none;
    margin-top: 36px;
    &.el-button--primary{
      background-color: #1E6DFF;
    }
  }
}
.login-background {
    // background: linear-gradient(90deg, #892FE8, #3D7EFF);
    background: #0B53D8;
    min-height: 100vh;
    position:relative;
    &::before{
      content:' ';
      position: absolute;
      border-width: 30vh 50vh;
      border-color:#1E6DFF;
      border-top-color: transparent;
      border-left-color: transparent;
      border-style: solid;
      right: 0;
      bottom: 0;
    }
    ::v-deep{
      .sidebar-logo-container{
        width: 20%;
        height: 66px;
      }
    }
}
</style>
