import request from '@/utils/request'
/**
 *
 * 内容管理
 *
 */

// 文章列表
export function getArticleList(params) {
  return request({
    url: '/admin/content/list',
    method: 'get',
    params
  })
}
// 文章详情
export function getArticleDetail(params) {
  return request({
    url: '/admin/content/detail',
    method: 'get',
    params
  })
}
// 文章新增
export function addArticle(data) {
  return request({
    url: '/admin/content/create',
    method: 'post',
    data
  })
}
// 文章编辑
export function updateArticle(data) {
  return request({
    url: '/admin/content/update',
    method: 'post',
    data
  })
}
// 文章删除
export function deleteArticle(data) {
  return request({
    url: '/admin/content/delete',
    method: 'post',
    data
  })
}
// 是否显示文章
export function isShowArticle(data) {
  return request({
    url: '/admin/content/changeShowStatus',
    method: 'post',
    data
  })
}
/**
 *
 * 分类管理
 *
 */

// 分类列表
export function getCategoryList(params) {
  return request({
    url: '/admin/category/list',
    method: 'get',
    params
  })
}
// 新增分类
export function addCategory(data) {
  return request({
    url: '/admin/category/create',
    method: 'post',
    data
  })
}
// 修改分类
export function updateCategory(data) {
  return request({
    url: '/admin/category/update',
    method: 'post',
    data
  })
}
// 删除分类
export function deleteCategory(data) {
  return request({
    url: '/admin/category/delete',
    method: 'post',
    data
  })
}
// 海报管理列表
export function getPosterList(params) {
  return request({
    url: '/admin/poster/list',
    method: 'get',
    params
  })
}
// 海报详情
export function getPosterDetail(params) {
  return request({
    url: '/admin/poster/detail',
    method: 'get',
    params
  })
}
// 海报删除
export function delPoster(data) {
  return request({
    url: '/admin/poster/delete',
    method: 'post',
    data
  })
}
// 海报新增
export function addPoster(data) {
  return request({
    url: '/admin/poster/create',
    method: 'post',
    data
  })
}
// 海报编辑
export function editPoster(data) {
  return request({
    url: '/admin/poster/update',
    method: 'post',
    data
  })
}

