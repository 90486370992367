<template>
  <div class="app-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-row :gutter="20" type="flex" align="middle" justify="space-between">
          <el-col :span="8">
            <span>管理员管理</span>
          </el-col>
          <el-col :span="16" style="text-align: right">
            <el-button v-if="roles[0].id === 1" size="small" type="primary" @click="funAdd">
              新增管理员
            </el-button>
          </el-col>
        </el-row>
      </div>
      <el-table
        v-loading="listLoading"
        :data="userList"
        element-loading-text="Loading"
        fit
        highlight-current-row
        :header-cell-style="{
          color: '#909399',
          background: '#F5F7FA',
        }"
      >
        <el-table-column align="center" label="姓名" width="180">
          <template slot-scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column label="账号" prop="account" min-width="100" />
        <el-table-column label="手机号" prop="phone" min-width="100" />
        <el-table-column label="邮箱" prop="email" min-width="120" />
        <el-table-column label="登录时间" min-width="100">
          <template slot-scope="scope">
            {{ scope.row.last_login_at | timeFilter }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="角色" min-width="150">
          <template slot-scope="scope">
            <el-button
              v-for="row in scope.row.roles"
              :key="row.id"
              type="primary"
              size="mini"
              plain
              disabled
            >{{ row.role_name }}</el-button>
          </template> </el-table-column>
        <el-table-column label="关联ID" prop="oa_id" />
        <el-table-column fixed="right" label="操作" min-width="150">
          <template slot-scope="scope">
            <span
              v-if="scope.row.roles.some(item => item.id === 1)"
              style="color: #909399"
            >
              超级管理员不允许修改
            </span>
            <!-- 自己不能编辑和自己同角色人员 -->
            <span v-else>
              <el-button
                size="mini"
                type="primary"
                :disabled="!isEditPermission(scope.row)"
                @click="funEdit(scope.$index, scope.row)"
              >
                编辑
              </el-button>
              <el-button
                v-if="roles[0].id===1"
                size="mini"
                type="danger"
                @click="funDelete(scope.$index, scope.row)"
              >
                删除
              </el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="spaceline" />
      <el-row :gutter="20">
        <el-col :span="24" style="text-align: right">
          <el-pagination
            background
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
    </el-card>
    <!--新增，编辑抽屉-->
    <el-drawer
      ref="drawer"
      :visible.sync="drawer"
      :with-header="false"
      :destroy-on-close="true"
      direction="rtl"
      size="90%"
    >
      <editUser v-if="isEdit" :id="userId" @closeDrawer="closeDrawer" @refreshList="refreshList" />
      <addUser v-else @closeDrawer="closeDrawer" @refreshList="refreshList" />
    </el-drawer>
  </div>
</template>

<script>
import { getUserList, deleteUser } from "@/api/system_setting";
import { parseTime } from "@/utils/index";
import addUser from './components/add.vue'
import editUser from './components/edit.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    addUser,
    editUser
  },
  filters: {
    timeFilter(timestamp) {
      return parseTime(timestamp, "{y}-{m}-{d} {h}:{i}");
    },
  },
  data() {
    return {
      userList: null,
      listLoading: true,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      // 抽屉状态
      drawer: false,
      // 是否编辑
      isEdit: false,
      // 当前编辑的用户id
      userId: '',
    };
  },
  computed: {
    ...mapGetters({
      roles: 'roles',
      collegeId: 'collegeId',
      name: 'name'
    })
  },
  created() {
    this.fetchData();
  },
  mounted() {
  },
  methods: {
    fetchData() {
      this.listLoading = true;
      const param = {
        page: this.currentPage,
        size: this.pageSize,
      };
      getUserList(param).then((response) => {
        const { list, meta } = response.data;
        this.userList = list;
        this.total = meta.total;
        this.currentPage = meta.page;
        this.pageSize = meta.page_size;
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchData();
    },
    funAdd() {
      this.isEdit = false
      this.drawer = true
      this.userId = ''
    },
    funEdit(index, row) {
      this.isEdit = true
      this.drawer = true
      this.userId = row.id
    },
    funDelete(index, row) {
      const that = this;
      that
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const param = {
            id: row.id,
          };
          deleteUser(param.id).then((response) => {
            that.$message.success(`删除成功`);
            that.fetchData();
            return true;
          });
        })
        .catch(() => {});
    },
    closeDrawer() {
      this.$refs.drawer.closeDrawer()
      this.drawer = false
    },
    refreshList() {
      this.fetchData();
    },
    // 判断是否允许编辑
    isEditPermission(row) {
      let flag = false;
      if (this.roles[0].id === 1) { // 超级管理员能编辑
        flag = true
      } else { // 非超管情况
        if (this.roles[0].id === row.roles[0].id && row.name === this.name) { // 同角色，同账号可以编辑
          flag = true
        } else { // 其他情况不可以编辑
          flag = false
        }
      }
      return flag
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  ::v-deep {
    .el-drawer__body{
      overflow-y: scroll
    }
  }
}
</style>

