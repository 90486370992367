const getters = {
  sidebar: state => getStateValue(state, 'app', 'sidebar'),
  size: state => getStateValue(state, 'app', 'size'),
  device: state => getStateValue(state, 'app', 'device'),
  visitedViews: state => getStateValue(state, 'tagsView', 'visitedViews'),
  cachedViews: state => getStateValue(state, 'tagsView', 'cachedViews'),
  token: state => getStateValue(state, 'user', 'token'),
  avatar: state => getStateValue(state, 'user', 'avatar'),
  name: state => getStateValue(state, 'user', 'name'),
  adminid: state => getStateValue(state, 'user', 'adminid'),
  introduction: state => getStateValue(state, 'user', 'introduction'),
  roles: state => getStateValue(state, 'user', 'roles'),
  collegeId: state => getStateValue(state, 'user', 'collegeId'),
  collegelist: state => getStateValue(state, 'user', 'collegelist'),
  positionlist: state => getStateValue(state, 'user', 'positionlist'),
  statuslist: state => getStateValue(state, 'user', 'statuslist'),
  sourcelist: state => getStateValue(state, 'user', 'sourcelist'),
  permissions: state => getStateValue(state, 'user', 'permissions'),
  permission_routes: state => getStateValue(state, 'permission', 'routes'),
  errorLogs: state => getStateValue(state, 'errorLog', 'logs')
}

function getStateValue(state, module, key) {
  const CurrentStateValue = state[module][key];
  const StorageValue = localStorage.getItem(`${module}.${key}`);
  if (StorageValue && StorageValue !== JSON.stringify(CurrentStateValue)) {
    state[module][key] = JSON.parse(StorageValue);
  }
  return state[module][key];
}
export default getters
