<template>
  <div class="dashboard-container">
    <template>
      <div class="boxs">
        <div v-for="item in boxTitles" :key="item.title" class="box" :style="{cursor: item.cursor}" @click="goto(item.target)">
          <div class="top">
            <div class="title">{{ item.title }}</div>
            <img :src="item.icon">
          </div>
          <div class="bottom" :style="{color: item.color}">
            {{ item.number }}<span v-if="item.icon===count_3">(学校导入{{ item.admin_num }} + 学院导入{{ item.college_num }})</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dashboard_bg from '@/assets/images/dashboard_bg.svg'
import count_1 from '@/assets/images/count_1.svg'
import count_2 from '@/assets/images/count_2.svg'
import count_3 from '@/assets/images/count_3.svg'
import count_4 from '@/assets/images/count_4.svg'
import count_5 from '@/assets/images/count_5.svg'
import count_6 from '@/assets/images/count_6.svg'
import count_7 from '@/assets/images/count_7.svg'
import { indexStatistic } from '@/api/system_setting'

export default {
  name: 'Dashboard',
  components: { },
  data() {
    return {
      statistic: {}, // 统计数据
      dashboard_bg,
      count_1,
      count_2,
      count_3,
      count_4,
      count_5,
      count_6,
      count_7,
      boxTitles: []
    }
  },
  computed: {
    ...mapGetters([
      'roles',
      'name'
    ])
  },
  created() {
    indexStatistic().then(res => {
      this.statistic = res.httpCode === 200 ? res.data : {};
      if (this.roles[0].id === 1) { // 超级管理员
        this.boxTitles = [
          {
            title: '总简历数',
            icon: count_1,
            color: '#005BEA',
            number: this.statistic.total,
            target: '/',
          }, {
            title: '在线投递数量',
            icon: count_2,
            color: '#67C23A',
            number: this.statistic.delivery_online_num,
            target: '/',
          }, {
            title: '后台导入数量',
            icon: count_3,
            color: '#B960FF',
            number: this.statistic.import_num,
            admin_num: this.statistic.import_admin_num,
            college_num: this.statistic.import_college_num,
            target: '/',
          }, {
            title: '待处理简历数',
            icon: count_4,
            color: '#E26767',
            number: this.statistic.pending_num,
            target: '/recruitment-manage/pending',
            cursor: 'pointer'
          }, {
            title: '已处理简历数',
            icon: count_5,
            color: '#23A1BC',
            number: this.statistic.not_pending_num,
            target: '/recruitment-manage/processed',
            cursor: 'pointer'
          }, {
            title: '回收站简历数',
            icon: count_6,
            color: '#606266',
            number: this.statistic.deleted_num,
            target: '/recruitment-manage/recycle',
            cursor: 'pointer'
          }, {
            title: '已录用人数',
            icon: count_7,
            color: '#FFB800',
            number: this.statistic.employed_num,
            target: '/recruitment-manage/processed?status=5',
            cursor: 'pointer'
          }
        ]
      } else { // 学院管理员
        this.boxTitles = [
          {
            title: '总简历数',
            icon: count_1,
            color: '#005BEA',
            number: this.statistic.total,
            target: '/',
          }, {
            title: '在线投递数量',
            icon: count_2,
            color: '#67C23A',
            number: this.statistic.delivery_online_num,
            target: '/',
          }, {
            title: '后台导入数量',
            icon: count_3,
            color: '#B960FF',
            number: this.statistic.import_num,
            admin_num: this.statistic.import_admin_num,
            college_num: this.statistic.import_college_num,
            target: '/',
          }, {
            title: '待处理简历数',
            icon: count_4,
            color: '#E26767',
            number: this.statistic.pending_num,
            target: '/recruitment-manage/pending-college',
            cursor: 'pointer'
          }, {
            title: '已处理简历数',
            icon: count_5,
            color: '#23A1BC',
            number: this.statistic.not_pending_num,
            target: '/recruitment-manage/processed-college',
            cursor: 'pointer'
          }, {
            title: '已录用人数',
            icon: count_7,
            color: '#FFB800',
            number: this.statistic.employed_num,
            target: '/recruitment-manage/processed-college?status=5',
            cursor: 'pointer'
          }
        ]
      }
    }).catch(() => {})
  },
  methods: {
    goto(target) {
      this.$router.push(target)
    },
  }
}
</script>
<style lang="scss" scoped>
.dashboard-container {
  padding: 20px;
  img{
    display: block;
    margin: 0 auto;
    margin-top: 100px;
  }
  span{
    color:#005BEA;
    color: #005BEA;
    display: block;
    text-align: center;
  }
  .boxs{
    width:100%;
    .box{
      display: inline-flex;
      flex-direction: column;
      width: 400px;
      height: 200px;
      margin-left: 12px;
      margin-top: 12px;
      padding:20px;
      // border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 9px 18px rgba(217,220,237,.6);
      .top{
        display: flex;
        height: 44px;
        justify-content: space-between;
        align-items: center;
        .title{

        }
        img{
          height: 24px;
          width: 24px;
          margin: 0px;
        }
      }
      .bottom{
        display: flex;
        align-items: center;
        margin-top: 16px;
        color:#005BEA;
        font-size:32px;
        font-weight: bold;
        span{
          display: flex;
          color:#000000;
          font-size: 14px;
          margin-top: 10px;
          margin-left: 10px;
          font-weight: 400
        }
      }
    }
  }
}
</style>
