<template>
  <div class="app-container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>添加管理员</span>
        <el-button
          style="float: right; padding: 0; margin-left: 14px"
          type="text"
          @click="$emit('closeDrawer')"
        >
          返回
        </el-button>
        <el-button
          style="float: right; padding: 0"
          type="text"
          @click="onSubmit('form')"
        >
          保存
        </el-button>
      </div>
      <el-form
        ref="form"
        class="common-form"
        :model="form"
        label-width="100px"
        :rules="rules"
        :inline-message="true"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email" />
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input v-model="form.account" />
        </el-form-item>
        <el-form-item label="关联ID" prop="oa_id">
          <el-input v-model="form.oa_id" autocomplete="new-password" placeholder="请输入关联ID，用于系统对接" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" autocomplete="new-password" show-password placeholder="请输入6位及以上密码" />
        </el-form-item>
        <el-form-item v-if="roles[0].id===1" label="角色" prop="role_id">
          <el-select
            v-model="form.role_id"
            filterable
            default-first-option
            disabled
            placeholder="请选择角色"
          >
            <el-option
              v-for="item in rolelist"
              :key="item.id"
              :label="item.role_name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="roles[0].id===1" label="学院" prop="college_id">
          <el-select
            v-model="form.college_id"
            filterable
            default-first-option
            placeholder="请选择学院"
          >
            <el-option
              v-for="item in collegelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" :disabled="disableSubmit" @click="onSubmit('form')">保存</el-button>
          <el-button @click="$emit('closeDrawer')">返回</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { addUser, getRoles } from "@/api/system_setting";
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      rolelist: [],
      form: {
        name: "",
        password: "",
        role_id: 2, // 默认为学院管理员
        college_id: ''
      },
      disableSubmit: false,
      rules: {
        name: [
          {
            max: 15,
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            message: "请输入正确的手机号",
            trigger: "blur",
            pattern: /^1[3-9][0-9]{9}$/
          }
        ],
        email: [
          {
            type: "email",
            message: "请输入邮箱",
            trigger: "blur",
            pattern: /^([a-zA-Z]|[0-9])(\w|\-|\.)+@(([a-zA-Z0-9]|\-|\.)*([a-zA-Z0-9]|\-))+\.[a-zA-Z]{2,}$/
          }
        ],
        role_id: [{ required: true, message: "请选择角色", trigger: "change" }],
        college_id: [{ required: true, message: "请选择学院", trigger: "change" }],
        account: [
          {
            required: true,
            message: "请输入登录名",
            trigger: "blur"
          },
          {
            max: 15,
            message: "长度在 1 到 15 个字符",
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(['collegeId', 'roles', 'collegelist'])
  },
  mounted() {
  },
  created() {
    this.fetchRoleData();
  },
  methods: {
    // getCollegeNameById(id) {
    //   const arr = this.collegelist.filter(item => { return item.id === this.collegeId })
    //   return arr[0] && arr[0].name
    // },
    fetchRoleData() {
      const param = {};
      getRoles(param)
        .then((response) => {
          this.rolelist = response.data.list.splice(0, 1); // 角色不能选择超管
        })
        .catch(() => {});
    },
    onSubmit(formName) {
      let insertData = {};
      if (this.roles[0].id === 1) { // 超级管理员
        insertData = {
          ...this.form,
          role_id: [this.form.role_id]
        };
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disableSubmit = true;
          addUser(insertData)
            .then((response) => {
              if (response.httpCode === 200 && response.status === 'success') {
                this.$message.success(`管理员添加成功`);
                this.$emit('closeDrawer') // 关闭抽屉
                this.$emit('refreshList') // 刷新列表数据
                this.disableSubmit = false;
                return true;
              } else {
                this.$message.error(response.message);
                this.disableSubmit = false;
                return false;
              }
            })
            .catch(() => {
              this.disableSubmit = false;
              return false;
            });
        } else {
          return false;
        }
      });
    },
    goto(target) {
      this.$router.push(target);
    }
  }
};
</script>
<style lang="scss" scoped>
.app-container {
  .el-input {
      width: 450px;
  }
  .el-select{
    width: 450px;
  }
}
</style>
