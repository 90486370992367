var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("编辑内容")]),
              _c(
                "el-button",
                {
                  staticStyle: {
                    float: "right",
                    padding: "0",
                    "margin-left": "14px",
                  },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeDrawer")
                    },
                  },
                },
                [_vm._v(" 返回 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", padding: "0" },
                  attrs: { type: "text", disabled: _vm.issubmit },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit("form")
                    },
                  },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                model: _vm.form,
                "label-width": "80px",
                rules: _vm.rules,
                "inline-message": true,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "内容标题", prop: "title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "内容分类", prop: "category_id" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      placeholder: "请选择分类",
                      options: _vm.categories,
                      "show-all-levels": false,
                      props: {
                        checkStrictly: true,
                        label: "name",
                        value: "id",
                        emitPath: false,
                      },
                      "popper-class": "cancelRadio",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.category_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "category_id", $$v)
                      },
                      expression: "form.category_id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "摘要", prop: "introduction" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 4, maxRows: 8 },
                      type: "textarea",
                    },
                    model: {
                      value: _vm.form.introduction,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "introduction", $$v)
                      },
                      expression: "form.introduction",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "缩略图" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "thumb-uploader",
                      attrs: {
                        action: _vm.apiAttachUpload,
                        headers: _vm.headers,
                        "show-file-list": false,
                        "on-success": _vm.handleThumbSuccess,
                        "before-upload": _vm.beforeThumbUpload,
                      },
                    },
                    [
                      _vm.form.thumbnail
                        ? _c("img", {
                            staticClass: "thumb",
                            attrs: { src: _vm.form.thumbnail },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus thumb-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "内容", required: "", prop: "content" } },
                [
                  _c(
                    "div",
                    { staticStyle: { border: "1px solid #ccc" } },
                    [
                      _c("Toolbar", {
                        staticStyle: { "border-bottom": "1px solid #ccc" },
                        attrs: {
                          editor: _vm.editor,
                          "default-config": _vm.toolbarConfig,
                        },
                      }),
                      _c("Editor", {
                        staticStyle: {
                          height: "400px",
                          "overflow-y": "hidden",
                        },
                        on: { onCreated: _vm.onCreated },
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "附件", prop: "att_content" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.apiAttachUpload,
                        headers: _vm.headers,
                        "on-remove": _vm.handleRemove,
                        "before-remove": _vm.beforeRemove,
                        "file-list": _vm.form.att_content,
                        "on-success": _vm.handleAttachSuccess,
                        "before-upload": _vm.beforeAttachUpload,
                        limit: _vm.configFile.limit,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "只能上传" +
                              _vm._s(_vm.configFile.fileupload_ext) +
                              "文件，且不超过" +
                              _vm._s(_vm.configFile.fileupload_size) +
                              "KB"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否显示" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.form.is_show,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_show", $$v)
                      },
                      expression: "form.is_show",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否置顶" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.form.is_topping,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_topping", $$v)
                      },
                      expression: "form.is_topping",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否投递" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.form.is_allow_delivery,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_allow_delivery", $$v)
                      },
                      expression: "form.is_allow_delivery",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布日期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "请选择发布日期",
                      format: "yyyy-MM-dd HH:mm",
                      "value-format": "yyyy-MM-dd HH:mm",
                    },
                    model: {
                      value: _vm.form.publish_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "publish_time", $$v)
                      },
                      expression: "form.publish_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "截止日期" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "请选择截止日期",
                      format: "yyyy-MM-dd HH:mm",
                      "value-format": "yyyy-MM-dd HH:mm",
                    },
                    model: {
                      value: _vm.form.end_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "end_time", $$v)
                      },
                      expression: "form.end_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "点击数", prop: "click_count" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "14" },
                    model: {
                      value: _vm.form.click_count,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "click_count", _vm._n($$v))
                      },
                      expression: "form.click_count",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "14" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", _vm._n($$v))
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "来源", prop: "publisher_name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.publisher_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "publisher_name", $$v)
                      },
                      expression: "form.publisher_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.issubmit },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit("form")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeDrawer")
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }