
<!--简历编辑组件-->
<template>
  <el-dialog title="编辑简历" :visible.sync="visible" :before-close="onCancel" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules" size="small">
      <el-form-item label="姓名" :label-width="formLabelWidth" prop="user_name">
        <el-input v-model="form.user_name" autocomplete="off" />
      </el-form-item>
      <el-form-item label="手机号" :label-width="formLabelWidth" prop="phone_number">
        <el-input v-model="form.phone_number" autocomplete="off" />
      </el-form-item>
      <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
        <el-input v-model="form.email" autocomplete="off" />
      </el-form-item>
      <el-form-item v-if="roles[0].id===1" label="意向学院" :label-width="formLabelWidth" prop="college_id">
        <el-select
          v-model="form.college_id"
          filterable
          default-first-option
          clearable
          placeholder="请选择学院"
        >
          <el-option
            v-for="item in collegelist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-else label="学院" :label-width="formLabelWidth">
        {{ collegeName(form.college_id) }}
      </el-form-item>
      <el-form-item label="简历" :required="true" :label-width="formLabelWidth">
        <el-upload
          class="upload-demo"
          :action="apiAttachUpload"
          :headers="headers"
          :on-success="handleAttachSuccess"
          :on-remove="handleRemove"
          :before-upload="beforeAttachUpload"
          :file-list="form.att_content"
        >
          <el-button type="primary" size="small">点击上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="onCancel">取 消</el-button>
      <el-button size="mini" type="primary" :disabled="disableSubmit" @click="onConfirm('form')">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editResume, resumeDetail } from "@/api/recruitment-manage";
import { apiAttachUpload, configFile } from '@/utils/constant'
import { mapGetters } from 'vuex'

export default {
  name: 'ImportDialog', // 导入简历组件
  props: {
    visible: {
      // 是否显示
      type: Boolean,
      default: function() {
        return true
      },
    },

  },
  data() {
    return {
      headers: { Authorization: this.$store.getters['token'] },
      apiAttachUpload: apiAttachUpload, // 通用上传接口,
      configFile: configFile, // 文件上传配置，比如格式，大小
      form: {
        att_content: []
      },
      formLabelWidth: '80px',
      disableSubmit: false, // 用于防止重复提交
      rules: {
        user_name: [
          {
            max: 15,
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          }
        ],
        phone_number: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            message: "请输入正确的手机号",
            trigger: "blur",
            pattern: /^1[3-9][0-9]{9}$/
          }
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "请输入邮箱",
            trigger: "blur"
          }
        ],
        college_id: [],
      }
    }
  },
  computed: {
    ...mapGetters(['collegeId', 'roles', 'collegelist'])
  },
  mounted() {

  },
  created() {

  },
  methods: {
    collegeName(id) {
      const result = this.collegelist.find(item => item.id === id)
      return result ? result.name : '-';
    },
    getResumeDetail(id) {
      resumeDetail({ id }).then(res => {
        this.form = res.httpCode === 200 ? res.data : [];
        // college_id=0带边未填写学院，前端组件显示转化为''
        if (this.form.college_id === 0) {
          this.form.college_id = ''
        }
      }).catch(() => {})
    },
    onCancel(row) {
      this.$emit('onCancel')
    },
    onConfirm(formName) {
      const that = this;
      const insertData = {
        ...this.form,
      };
      console.log(insertData)
      if (this.form.att_content.length === 0) { // 校验是否上传附件
        this.$message.error("请上传简历")
        return false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disableSubmit = true;
          editResume(insertData)
            .then((response) => {
              if (response.httpCode === 200 && response.status === 'success') {
                this.$message.success(`修改成功`);
                this.$emit('onRefresh')
                this.$emit('onCancel')
                this.disableSubmit = false;
                setTimeout(() => {
                  // 超级管理员和学院管理员跳转路径不一样
                  that.roles[0].id === 1 ? that.$router.push("/recruitment-manage/pending") : that.$router.push("/recruitment-manage/pending-college");
                }, 1000);
                return true;
              } else {
                this.$message.error(response.message);
                this.disableSubmit = false;
                return false;
              }
            })
            .catch(() => {
              this.disableSubmit = false;
              return false;
            });
        } else {
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      const index = this.form.att_content.findIndex(item => item.url === file.url)
      if (index > -1) {
        this.form.att_content.splice(index, 1)
      }
    },
    handleAttachSuccess(res, file) {
      if (res.httpCode === 200 && res.statusCode === 0) {
        const info = { filename: res.data.file_name, name: res.data.file_name, url: res.data.url, filesize: res.data.size }
        // this.form.att_content = [] //限制只上传一个，后面会把前面的顶下去
        this.form.att_content.push(info)
      } else {
        this.$message.error(res.message)
        return false
      }
    },
    beforeAttachUpload(file) {
      if (this.form.att_content.length > 2) {
        this.$message.error('最多上传3个附件')
        return false
      }
      const configFileExtArr = configFile.fileupload_ext
      const filename_arr = file.name.split('.')
      const filetype = filename_arr[filename_arr.length - 1].toLowerCase();
      if (!configFileExtArr.includes(filetype)) {
        this.$message.error('上传文件格式不允许')
        return false
      }
      if (file.size / 1024 > configFile.fileupload_size) {
        this.$message.error(`上传文件最大为${configFile.fileupload_size}kb`)
        return false
      }
      return true
    },
  }
}
</script>
  <style lang="scss" scoped>

  </style>

