var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchForm, size: "mini" },
            },
            [
              _c(
                "el-row",
                {
                  attrs: {
                    type: "flex",
                    justify: "start",
                    gutter: 20,
                    align: "middle",
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: "姓名", clearable: "" },
                            model: {
                              value: _vm.searchForm.user_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "user_name", $$v)
                              },
                              expression: "searchForm.user_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "简历来源" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: { clearable: "", placeholder: "简历来源" },
                              model: {
                                value: _vm.searchForm.source,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "source", $$v)
                                },
                                expression: "searchForm.source",
                              },
                            },
                            _vm._l(_vm.sourcelist, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.search },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-row"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-row",
                {
                  attrs: {
                    gutter: 20,
                    type: "flex",
                    align: "middle",
                    justify: "space-between",
                  },
                },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("span", [_vm._v("待处理简历列表")]),
                  ]),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 16 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.importDialogVisible = true
                            },
                          },
                        },
                        [_vm._v(" 导入简历 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.table_loading,
                  expression: "table_loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableList,
                "tooltip-effect": "dark",
                "header-cell-style": {
                  color: "#909399",
                  background: "#F5F7FA",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "姓名",
                  width: "120",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.user_name))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phone_number",
                  label: "手机号",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "email",
                  label: "邮箱",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "delivery_time",
                  label: "创建时间",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "source",
                  label: "简历来源",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              plain: "",
                              disabled: "",
                            },
                          },
                          [_vm._v(_vm._s(_vm.sourceName(scope.row.source)))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "assigned_college_time",
                  label: "分发时间",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _vm.roles[0].id === 2 && scope.row.status <= 2
                          ? _c(
                              "el-popconfirm",
                              {
                                staticStyle: { margin: "0 10px" },
                                attrs: { title: "请再次确认是否简历通过？" },
                                on: {
                                  onConfirm: function ($event) {
                                    return _vm.handleInitalPass(scope.row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "mini",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("简历通过")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.roles[0].id === 2 && scope.row.status <= 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleInitalNoPass(scope.row)
                                  },
                                },
                              },
                              [_vm._v("简历不通过")]
                            )
                          : _vm._e(),
                        _vm.roles[0].id === 2 && scope.row.status === 5
                          ? _c(
                              "el-popconfirm",
                              {
                                staticStyle: { margin: "0 10px" },
                                attrs: { title: "请再次确认是否学院通过？" },
                                on: {
                                  onConfirm: function ($event) {
                                    return _vm.handlePass(scope.row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "mini",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("学院通过")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.roles[0].id === 2 && scope.row.status === 5
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleNoPass(scope.row)
                                  },
                                },
                              },
                              [_vm._v("学院不通过")]
                            )
                          : _vm._e(),
                        _vm.roles[0].id === 2 &&
                        scope.row.import_admin_id === _vm.adminid
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "spaceline" }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { span: 16, offset: 8 },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.pagesize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ImportDialog", {
        attrs: { visible: _vm.importDialogVisible },
        on: {
          onCancel: _vm.onCancel,
          onConfirm: _vm.onConfirm,
          onRefresh: _vm.onRefresh,
        },
      }),
      _c("DetailDialog", {
        ref: "detailDialog",
        attrs: { visible: _vm.detailDialogVisible },
        on: { onCancel: _vm.onCancel, onHistory: _vm.onHistory },
      }),
      _c("EditDialog", {
        ref: "editDialog",
        attrs: { visible: _vm.editDialogVisible },
        on: {
          onCancel: _vm.onCancel,
          onConfirm: _vm.onConfirm,
          onRefresh: _vm.onRefresh,
        },
      }),
      _c("NopassDialog", {
        ref: "nopassDialog",
        attrs: { visible: _vm.nopassDialogVisible, "no-pass-type": 2 },
        on: {
          onCancel: _vm.onCancel,
          getCollegeNoPassReason: _vm.getCollegeNoPassReason,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }