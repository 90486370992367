
<!--简历下发组件-->
<template>
  <el-dialog title="分发" :visible.sync="visible" :before-close="onCancel" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules" size="small">
      <el-form-item label="姓名" :label-width="formLabelWidth" :required="true">
        {{ detail.user_name }}
      </el-form-item>
      <el-form-item label="手机号" :label-width="formLabelWidth" :required="true">
        {{ detail.phone_number }}
      </el-form-item>
      <el-form-item label="邮箱" :label-width="formLabelWidth" :required="true">
        {{ detail.email }}
      </el-form-item>
      <el-form-item label="意向学院" :label-width="formLabelWidth">
        {{ getCollegeName(detail.college_id) || '-' }}
      </el-form-item>
      <el-form-item v-if="roles[0].id===1" label="分发学院" :label-width="formLabelWidth" prop="college_id">
        <el-select
          v-model="form.college_id"
          filterable
          default-first-option
          clearable
          placeholder="请选择学院"
          value-key="id"
          @change="changeCollege"
        >
          <el-option
            v-for="item in collegeAdminList"
            :key="item.id"
            :label="item.name"
            :value="item"
          />
        </el-select>
        <!-- <el-cascader
          v-model="form.college_id"
          placeholder="请选择学院"
          clearable
          filterable
          :options="collegeAdminList"
          :props="{ expandTrigger: 'hover',value:'id',children:'child',label:'name',emitPath:'false'}"
        /> -->
      </el-form-item>
      <el-form-item v-else label="学院" :required="true" :label-width="formLabelWidth">
        {{ getCollegeNameById(collegeId) }}
      </el-form-item>
      <el-form-item v-if="roles[0].id===1" label="分发给" prop="assigned_college_admin_id" :label-width="formLabelWidth">
        <el-select
          v-model="form.assigned_college_admin_id"
          filterable
          default-first-option
          clearable
          placeholder="请选择学院管理员"
        >
          <el-option
            v-for="item in collegeAdminChild"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="onCancel">取 消</el-button>
      <el-button size="mini" type="primary" :disabled="disableSubmit" @click="onConfirm('form')">下发</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { collegeAdminList, adminAssign, collegeAdminAssign } from "@/api/recruitment-manage";
import { mapGetters } from 'vuex'

export default {
  name: 'AssignDialog', // 导入简历组件
  props: {
    visible: {
      // 是否显示
      type: Boolean,
      default: function() {
        return true
      },
    },

  },
  data() {
    return {
      form: {},
      detail: {}, // 当前行数据详情,从父组件带过来
      collegeAdminList: [], // 学院管理员列表包含学院和学院管理员
      collegeAdminChild: [], // 当前学院的学院管理员列表
      formLabelWidth: '80px',
      disableSubmit: false, // 用于防止重复提交
      rules: {
        college_id: [
          {
            required: true,
            message: "请选择学院或该学院下没有管理员",
            trigger: "change"
          }
        ],
        assigned_college_admin_id: [
          {
            required: true,
            message: "请选择分发人",
            trigger: "change"
          }
        ],
        share_user_id: [
          {
            required: true,
            message: "请选择分发人",
            trigger: "change"
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['collegeId', 'roles', 'collegelist'])
  },
  mounted() {
  },
  created() {
    // 管理员调用学院管理员列表
    this.roles[0].id === 1 && collegeAdminList().then(res => { this.collegeAdminList = res.httpCode === 200 ? res.data : [] }).catch(() => {});
  },
  methods: {
    getCollegeNameById(id) { // 获取当前用户所在学院名称
      const arr = this.collegelist.filter(item => { return item.id === this.collegeId })
      return arr[0] && arr[0].name
    },
    getCollegeName(id) { // 根据学院id获取学院名称
      const arr = this.collegelist.filter(item => { return item.id === id })
      return arr[0] && arr[0].name
    },
    onCancel(row) {
      this.$emit('onCancel')
    },
    onConfirm(formName) {
      const that = this;
      let insertData = {};
      if (this.roles[0].id === 1) { // 超级管理员
        insertData = {
          resume_id: this.detail.id,
          assigned_college_admin_id: this.form.assigned_college_admin_id
        };
      } else { // 学院管理员
        insertData = {
          resume_id: this.detail.id,
          share_user_id: this.form.share_user_id
        };
      }
      console.log(insertData)

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.disableSubmit = true;
          if (this.roles[0].id === 1) {
            adminAssign(insertData)
              .then((response) => {
                if (response.httpCode === 200 && response.status === 'success') {
                  this.$message.success(`分发成功`);
                  this.$emit('onRefresh')
                  this.$emit('onCancel')
                  setTimeout(() => {
                    // 超级管理员和学院管理员跳转路径不一样
                    that.roles[0].id === 1 ? that.$router.push("/recruitment-manage/pending") : that.$router.push("/recruitment-manage/pending-college");
                  }, 1000);
                  return true;
                } else {
                  this.$message.error(response.message);
                  this.disableSubmit = false;
                  return false;
                }
              })
              .catch(() => {
                this.disableSubmit = false;
                return false;
              });
          } else {
            collegeAdminAssign(insertData)
              .then((response) => {
                if (response.httpCode === 200 && response.status === 'success') {
                  this.$message.success(`分发成功`);
                  this.$emit('onCancel')
                  setTimeout(() => {
                    that.$router.push("/recruitment-manage/pending-college");
                  }, 1000);
                  return true;
                } else {
                  this.$message.error(response.message);
                  this.disableSubmit = false;
                  return false;
                }
              })
              .catch(() => {
                this.disableSubmit = false;
                return false;
              });
          }
        } else {
          return false;
        }
      });
    },
    getRowDetail(row) {
      this.detail = row;
      // 如果投递选择学院则回显学院
      this.form.college_id = row.college_id ? { id: row.college_id } : ""
      // 初次手动给学院管理员列表赋值,因为回显触发change
      this.collegeAdminChild = this.getCollegeAdminChild(row.college_id);
      //  清除上次选择的学院管理员
      this.form.assigned_college_admin_id = ""
      this.form = { ...this.form }
      // 清除校验信息
      this.$nextTick(() => {
        this.$refs['form'].clearValidate();
      })
    },
    getCollegeAdminChild(id) {
      const result = this.collegeAdminList.find(item => item.id === id)
      return result ? result.child : [];
    },
    changeCollege(val) {
      this.collegeAdminChild = val.child;
      // 清除上次选择的学院管理员
      this.form.assigned_college_admin_id = ""
      this.form = { ...this.form }
    }
  }
}
</script>
  <style lang="scss" scoped>

  </style>

